.cu-section__animation-wipe {
	//display: none;
	width: 1920px;
	height: 100vh;
	position: fixed;
	top: -100vh;
	right: calc((100% - 1920px)/2);
	z-index: 100;

	.box-1,
	.box-2 {
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 0;
			position: absolute;
			top: 0;
			left: 0;
			background: $white;

			transition: height 0.5s 0.1s ease-out;
		}

		&:after {
			content: '';
			display: block;
			width: 0;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			background: $white;

			transition: width 0.5s 0.1s ease-out;
		}
	}

	.box-2 {
		z-index: 2;

		&:before {
			top: auto;
			bottom: 0;
		}

		&:after {
			right: auto;
			left: 0;
		}
	}

	.loader {
		display: block;
		width: 100px;
		height: 100px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-27deg);
		z-index: 5;
		opacity: 0;
		background: url('../svg/loading.svg') center no-repeat;
		background-size: contain;

		transition: opacity 0.1s ease-out;
	}

	&.top {
		top: 0;
	}

	&.show {
		.box-1,
		.box-2 {
			&:before {
				height: 100%;
				transition: height 0.5s ease-in;
			}

			&:after {
				width: 100%;
				transition: width 0.5s ease-in;
			}
		}

		.loader {
			opacity: 1;
			transition: opacity 0.1s 0.5s ease-in;
		}
	}

	@include max-down(1921px) {
		width: 100%;
		right: 0;
	}
}