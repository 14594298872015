.mobile-menu-shown {
	.header-wrap {
		.header-top {
			display: none;
		}

		.header {
			.logo {
				background: url('../svg/full-logo-color-stacked.svg') center no-repeat;
				background-size: contain;

				@include max-down(450px) {
					background-image: url('../svg/logo-mark.svg');
				}
			}

			.header-right {
				.mobile-menu-phone {
					display: inline-block;
				}

				.search-icon {
					display: none;
				}

				.mobile-menu-toggle {
					.icon span {
						background: $gold;
					}

					.text {
						color: $gold;
					}
				}
			}
		}

		&.scrolled {
			background: none;
			box-shadow: none;
			transition: background .3s .3s;
		}
	}

	&.notice-shown {
		.header-wrap {
			top: 0;
			transition: top .3s .3s;
		}
	}
}

.mobile-menu {
	display: block;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 15;
	background: $white;
	overflow: auto;
	transform: translateX(100%);
	transition: transform .4s ease-out;

	&.active {
		transform: translateX(0);
	}

	.inner-wrap {
		width: calc(100% - 60px);
		margin: 0 auto;
		padding: 120px 0 30px 0;
	}

	.mobile-menu-nav {
		li {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			a {
				display: block;
				position: relative;
				padding-left: 30px;

				font-family: $ec_font;
				font-size: 33px;
				font-weight: 600;
				letter-spacing: 0.1em;
				text-transform: uppercase;
				text-decoration: none;
				color: $blue;

				&:before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					position: absolute;
					top: 8px;
					left: 0;
					background: url('../svg/plus.svg') center no-repeat;
					background-size: contain;
				}
			}

			&.active {
				> a:before {
					top: 10px;
					transform: rotate(45deg);
				}
			}

			.sub-menu {
				display: none;
				padding-top: 20px;
				padding-left: 30px;

				a {
					padding-left: 20px;

					font-size: 18px;
					font-weight: 700;
					letter-spacing: 0.068em;

					&:before {
						width: 11px;
						height: 11px;
						top: 5px;
					}
				}
			}
		}

		@include max-down(425px) {
			li {
				a {
					padding-left: 22px;
					font-size: 26px;
					letter-spacing: 0.068em;

					&:before {
						width: 14px;
						height: 14px;
						top: 8px;
					}
				}

				.sub-menu {
					padding-left: 15px;
				}
			}
		}
	}

	.mobile-menu-bottom-nav {
		width: 285px;
		margin: 30px 0 0;

		ul {
			columns: 2;
		}

		li {
			margin: 0;
			padding: 0;
		}

		a {
			display: block;
			position: relative;
			padding-left: 12px;

			font-family: $c_font;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 0.068em;
			line-height: 34px;
			color: $gray;
			text-transform: uppercase;
			text-decoration: none;

			&:before {
				content: '';
				display: block;
				width: 1px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background: $gray;
			}

			@include non-touch {
				&:hover {
					color: $dblue;
				}
			}
		}

		@include max-down(600px) {
			margin: 30px auto 0;
		}

		@include max-down(425px) {
			width: 100%;

			ul {
				columns: 1;
			}
		}
	}

	.bottom-wrap {
		margin-top: 60px;

		.button-wrap {
			margin-bottom: 20px;

			&.split {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 20px;

				.half {
					display: block;
					width: 50%;
				}
			}

			.btn {
				width: 100%;
			}

			@include max-down(600px) {
				&.split {
					display: block;

					.half {
						width: 100%;
						margin-bottom: 20px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		.search-form {
			font-size: 0;

			input[type="text"] {
				appearance: none;
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 45px);
				height: 45px;
				background: rgba($white, 0.45);
				border: 2px solid $blue;
				padding: 0 20px;
				margin: 0;

				font-family: $c_font;
				font-size: 1rem;
				font-weight: 700;
				letter-spacing: 0.08em;
				color: $blue;

				&:placeholder {
					opacity: 1;
					color: $blue;
				}

				&::placeholder {
					opacity: 1;
					color: $blue;
				}
			}

			button[type="submit"] {
				@include hidden-text;
				display: inline-block;
				vertical-align: middle;
				min-width: 0;
				width: 45px;
				height: 45px;
				background: $blue url('../svg/search-icon-gold.svg') center no-repeat;
				background-size: 19px 20px;
				padding: 0;
				margin: 0;
				border: 0;
			}
		}
	}
}