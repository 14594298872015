.cu-section__feature-blocks {
	padding: 80px 0;
	background: $blue;

	.grid {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		width: 1215px;
		margin: 0 auto;

		.grid-item {
			position: relative;
			padding: 45px 55px 140px 55px;
			border-bottom: 2px solid $white;
			border-right: 2px solid $white;
			transition: background .3s;

			&:nth-child(3n) {
				border-right: 0;
			}

			&:nth-last-child(-n+1),
			&:nth-last-child(-n+2),
			&:nth-last-child(-n+3) {
				border-bottom: 0;
			}

			h2 {
				font-size: 28px;
				line-height: 1.21em;
				transition: color .3s;
			}

			.copy {
				transition: color .3s;
			}

			.button-wrap {
				position: absolute;
				bottom: 45px;
				left: 55px;
			}

			@include non-touch {
				&:hover {
					background: $white;

					h2 {
						color: $blue;
					}

					.copy {
						color: $blue;
					}

					.btn {
						background: $dblue;
						border-color: $dblue;
					}
				}
			}
		}
	}

	&.white-bg {
		background: none;

		.grid .grid-item {
			border-bottom-color: $blue !important;
			border-right-color: $blue !important;

			@include non-touch {
				&:hover {
					background: $blue;

					h2,
					.copy {
						color: $white;
					}
				}
			}
		}
	}

	@include max-down(1350px) {
		.grid {
			width: 90%;
		}
	}

	@include max-down(1000px) {
		.grid {
			grid-template-columns: 1fr 1fr;

			.grid-item {
				&:nth-child(3n) {
					border-right: 2px solid $white;
				}

				&:nth-child(2n) {
					border-right: 0;
				}

				&:nth-last-child(-n+3) {
					border-bottom: 2px solid $white;
				}

				&:nth-last-child(-n+1),
				&:nth-last-child(-n+2) {
					border-bottom: 0;
				}
			}
		}
	}

	@include max-down(750px) {
		.grid {
			.grid-item {
				padding-left: 10%;
				padding-right: 10%;

				.button-wrap {
					left: 10%;
				}
			}
		}
	}

	@include max-down(600px) {
		.grid {
			grid-template-columns: 1fr;
			width: calc(100% - 100px);

			.grid-item {
				border-right: 0 !important;
				border-bottom: 2px solid $white !important;
				text-align: center;
				padding-left: 5%;
				padding-right: 5%;

				&:last-child {
					border-bottom: 0 !important;
				}

				h2:before {
					margin-left: auto;
					margin-right: auto;
				}

				.button-wrap {
					width: 80%;
					text-align: center;
				}
			}
		}
	}
}