.page-template-page-divisions {
	.page-background-wrap {
		position: relative;
		z-index: 1;
		padding-top: 120px;
		padding-bottom: 275px;

		@include max-down(1000px) {
			padding-top: 100px;
		}

		@include max-down(750px) {
			padding-top: 75px;
			padding-bottom: 75px;
		}

		@include max-down(600px) {
			padding: 40px 0;
		}
	}

	.division {
		width: 1210px;
		height: 435px;
		position: relative;
		z-index: 2;
		margin: 0 auto 100px auto;
		box-shadow: 6px 6px 24px rgba($black, 0.16);
		background: $white;

		.image {
			width: 470px;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.info {
			width: calc(100% - 470px);
			height: 100%;
			margin: 0 0 0 auto;

			.inner-content-wrap {
				@include section-line-before(60px, 20px);
				width: calc(100% - 140px);
				margin: 0 auto;
				padding-left: 80px;

				.button-wrap {
					margin-top: 35px;
				}
			}
		}

		@include max-down(1350px) {
			width: 90%;

			.info .inner-content-wrap {
				@include section-line-before-break;
				padding-left: 0;
			}
		}

		@include max-down(1160px) {
			.image {
				width: 325px;
			}

			.info {
				width: calc(100% - 325px);

				.inner-content-wrap {
					width: calc(100% - 70px);
				}
			}
		}

		@include max-down(1000px) {
			height: auto;

			.image {
				width: 100%;
				height: 300px;
				position: static;
			}

			.info {
				width: 100%;
				height: auto;

				.inner-content-wrap {
					padding: 55px 0;
				}
			}
		}

		@include max-down(750px) {
			margin-bottom: 75px;
		}
	}

	.cu-section__stats {
		padding-top: 50px;
		padding-bottom: 150px;
		z-index: 1;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 715px;
			position: absolute;
			top: -715px;
			right: -65px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;

			@include max-down(750px) {
				display: none;
			}
		}

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -455px;
			left: -320px;
			background: url('../svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;

			@include max-down(1700px) {
				bottom: -525px;
			}

			@include max-down(750px) {
				display: none;
			}
		}

		@include max-down(750px) {
			padding-top: 0;
			padding-bottom: 75px;
		}
	}

	.cu-section__contact-bar {
		margin-top: 205px;

		@include max-down(1000px) {
			margin-top: 100px;
		}

		@include max-down(750px) {
			margin-top: 75px;
		}
	}
}