.cu-section__event-list {
	padding: 160px 0 175px 0;

	> .info {
		width: 800px;
		margin: 0 auto;

		.button-wrap {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
			row-gap: 20px;
			width: 380px;
			margin: 40px auto 0 auto;

			&.one-button {
				display: block;
				width: 100%;
				text-align: center;
			}
		}
	}

	.event-list {
		width: 1470px;
		margin: 120px auto 0 auto;
		font-size: 0;
		text-align: center;

		&.tbx-animated {
			.filter-list-item .image img {
				opacity: 0;
			}

			&.tbx-animated-siv-ft {
				.filter-list-item[data-transition-delay="0.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.1s);
				}
				.filter-list-item[data-transition-delay="0.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.2s);
				}
				.filter-list-item[data-transition-delay="0.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.3s);
				}
				.filter-list-item[data-transition-delay="0.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.4s);
				}
				.filter-list-item[data-transition-delay="0.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.5s);
				}
				.filter-list-item[data-transition-delay="0.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.6s);
				}
				.filter-list-item[data-transition-delay="0.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.7s);
				}
				.filter-list-item[data-transition-delay="0.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.8s);
				}
			}
		}

		.cu-card__event {
			display: inline-block;
			vertical-align: top;
			width: 345px;
			margin-right: 30px;
			margin-bottom: 30px;
			text-align: left;

			&:last-child {
				margin-right: 0;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}

	@include max-down(1600px) {
		.event-list {
			width: 95%;

			.cu-card__event {
				width: calc(25% - calc(90px / 4));
			}
		}
	}

	@include max-down(1260px) {
		.event-list {
			.cu-card__event {
				width: calc(33.33333% - 20px);

				&:nth-child(4n) {
					margin-right: 30px;
				}

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(950px) {
		> .info {
			width: 90%;
		}

		.event-list {
			margin-top: 75px;

			.cu-card__event {
				width: calc(50% - 15px);

				&:nth-child(3n) {
					margin-right: 30px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(600px) {
		padding: 100px 0;

		> .info {
			width: calc(100% - 80px);

			.button-wrap {
				width: 100%;
				grid-template-columns: 1fr;
				text-align: center;
			}
		}

		.event-list {
			width: calc(100% - 40px);

			.cu-card__event {
				width: 100%;
				margin-right: 0 !important;
			}
		}
	}
}