button[type="button"] {
	padding: 0;
	background: none;
	border: 0;
	outline: 0;
	cursor: pointer;

	&.btn {
		padding: 10px 5px;
		border: 2px solid $gold;

		&.blue {
			border-color: $blue;
		}
	}
}

.btn, input[type="submit"], button[type="submit"] {
	appearance: none;
	display: inline-block;
	vertical-align: middle;
	min-width: 160px;
	border: 2px solid $gold;
	background: $gold;
	outline: 0;
	border-radius: 0;
	padding: 8px 15px 10px;
	margin: 0;
	cursor: pointer;

	font-family: $c_font;
	font-size: 1rem;
	line-height: 1em;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	color: $blue;
	text-decoration: none;
	text-align: center;

	&.active {
		background: $gold;
	}

	&.no-bg {
		background: none;
		color: $dblue;
	}

	&.text-white {
		color: $white;
	}

	&.text-dblue {
		color: $dblue;
	}

	&.blue {
		background: $blue;
		border-color: $blue;
		color: $gold;
	}

	&.white {
		background: $white;
		border-color: $white;
		color: $dblue;
	}

	&.gray {
		background: $altgray;
		border-color: $altgray;
		color: $dblue;
	}

	&.large {
		min-width: 180px;
	}

	&.medium {
		min-width: 140px;
	}

	&.small {
		min-width: 100px;
	}

	&.disabled,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;
		cursor: not-allowed;
	}

	@include non-touch {
		transition: background .3s, color .3s, border-color .3s;

		&:hover {
			background: $dblue;
			border-color: $dblue;
			color:$white;
		}

		&.no-bg {
			&:hover {
				background: $gold;
				border-color: $gold;
				color: $white;
			}
		}

		&.blue {
			&:hover {
				background: $gold;
				border-color: $gold;
				color: $blue;
			}
		}

		&.gray {
			&:hover {
				background: $blue;
				border-color: $blue;
				color: $gold;
			}
		}

		&.text-dblue {
			&:hover {
				background: $blue;
				border-color: $blue;
				color: $white;
			}
		}

		&.white {
			&:hover {
				background: $dblue;
				border-color: $dblue;
				color: $white;
			}
		}

		&.active {
			&:hover {
				background: $blue;
				border-color: $blue;
				color: $gold;
			}
		}
	}

	@include max-down(600px) {
		width: 100%;
	}
}

.text-btn {
	appearance: none;
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: auto;
	border: 0;
	border-radius: 0;
	background: none;
	outline: 0;
	padding: 0;
	margin: 0;
	cursor: pointer;

	font-family: $c_font;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-decoration: none;
	color: $white;

	transition: color .3s;

	&.blue {
		color: $blue;
	}

	&.dblue {
		color: $dblue;
	}

	&.gold {
		color: $gold;
	}

	&.small {
		font-size: 0.875rem;
	}

	@include non-touch {
		&:hover {
			color: $gold !important;
		}

		&.gold:hover {
			color: $blue !important;
		}

		&.hover-white {
			&:hover {
				color: $white !important;
			}
		}
	}
}

.plus-btn {
	appearance: none;
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: auto;
	position: relative;
	border: 0;
	border-radius: 0;
	background: none;
	outline: 0;
	padding: 0;
	padding-left: 22px;
	margin: 0;
	cursor: pointer;

	font-family: $c_font;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-decoration: none;
	color: $blue;

	&:before {
		content: '';
		display: block;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 4px;
		left: 0;
		background: url('../svg/plus.svg') center no-repeat;
		background-size: contain;
	}

	&.white {
		color: $white;
	}

	@include non-touch {
		transition: color .3s;
		
		&:hover {
			color: $gold !important;
		}
	}
}

.arrow-btn {
	appearance: none;
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: auto;
	position: relative;
	border: 0;
	border-radius: 0;
	background: none;
	outline: 0;
	padding: 0;
	padding-right: 27px;
	margin: 0;
	cursor: pointer;

	font-family: $c_font;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	text-decoration: none;
	color: $blue;

	&:after {
		content: '';
		display: block;
		width: 12px;
		height: 10px;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		background: url('../svg/button-arrow-blue.svg') center no-repeat;
		background-size: contain;
	}

	&.back {
		padding-right: 0;
		padding-left: 27px;

		&:after {
			display: none;
		}

		&:before {
			content: '';
			display: block;
			width: 12px;
			height: 10px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%) rotate(180deg);
			background: url('../svg/button-arrow-blue.svg') center no-repeat;
			background-size: contain;
		}

		@include non-touch {
			transition: color .3s;
			
			&:hover {
				color: $gold;
			}
		}
	}

	&.top {
		&:after {
			transform: translateY(-50%) rotate(-90deg);
		}
	}

	&.white {
		color: $white;

		&:after {
			background: url('../svg/button-arrow-white.svg') center no-repeat;
			background-size: contain;
		}

		&.back:before {
			background: url('../svg/button-arrow-white.svg') center no-repeat;
			background-size: contain;
		}
	}

	&.gold {
		color: $gold;

		&:after {
			background: url('../svg/button-arrow-gold.svg') center no-repeat;
			background-size: contain;
		}

		&.back {
			&:before {
				background: url('../svg/button-arrow-gold.svg') center no-repeat;
				background-size: contain;
			}
			
			@include non-touch {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}