
	.cu-section__cta { 

        .left {
            padding: 55px 20px;
        }
    }
		
	

