.page-template-page-components, .program-template-page-components {
	color:$blue;
	.copy {color:$blue;}
	.dkbluetext {color:$blue;}
	.subline {display: inline-block; border-bottom: solid 3px $gold; padding-bottom:6px;}
	.internal-link {scroll-margin-top:100px;}
	.cu-section__simple-content .inner-wrap {padding-bottom:65px;}	
	ol.circlednumbers {
		list-style: none;
		counter-reset: steps;
		padding-left:50px;
	  }
	  ol.circlednumbers li {
		counter-increment: steps;
	  }
	  ol.circlednumbers li::before {
		content: counter(steps);
		margin-right: 0.5rem;
		background: $blue;
		color: white;
		width: 2em;
		height: 2em;
		border-radius: 50%;
		display: inline-grid;
		place-items: center;
		line-height: 2em;
		font-weight:bold;
		position:absolute;
		margin-left:-45px;
	  }
	  ol.circlednumbers ol li::before {
		background: $gold;
	  }

	.cu-section__simple-content.singletextrow.no-bg.ltgray {background-color:$lgray;}

	.cu-section__simple-content.singletextrow {
		margin: 10px 0px 40px 0px;

		blockquote {margin-bottom:40px; padding:40px;}
		.withborder {border: solid 2px $blue;}
		.concentration-block {width:100%; padding: 40px;}
		.concentration-block-gray {width:100%; padding: 40px; background-color:$lgray;}


	}

	.cu-section__simple-content.singletextrow .clearfloat{
		clear: both;
	}


	.cu-section__simple-content.singletextrow .inner-wrap:before {
		display:none;
	}

	.cu-section__simple-content.singletextrow .inner-wrap {
		padding:0px;
		width: 100%; max-width:1400px;
		.bottomlinewrapper {border-bottom: solid 2px $gold; text-align: center; margin-left:auto; margin-right:auto; display: inline-block; padding-bottom: 0px; margin-bottom: 15px; text-align: center;}
		.copy {margin-left:auto; margin-right:auto;}
	}
	.cu-section__simple-content.singletextrow .inverted, .cu-section__simple-content.singletextrow.inverted {
		background-color:$blue;
		color: $white;
		.copy, h2, h3 {color:$white;
		}	
	}

	@media only screen and (max-width: 1549px) {
		.cu-section__simple-content.singletextrow .inner-wrap  {
			width:90%
		}
	}



	@media only screen and (min-width: 1259px) {
		.cu-section__simple-content.singletextrow .inner-wrap  {
			padding: 0px 80px;
		}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar {padding: 0px 0px 0px 80px;}
	}


	@media only screen and (min-width: 1000px) {
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {margin-left:50px; margin-bottom:50px;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .copy {display:block; flex:none;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {width:400px; float:right;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar.NotWrapped .textsection {
			width: 830px;
			max-width: calc(100% - 450px);
			float: left;
			}
		
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar.FixedContent .textsection {
				width: 830px;
				max-width: calc(100% - 450px);
				float: left;
			}
	}

	@media only screen and (max-width: 999px) {

		.cu-section__simple-content.singletextrow .inner-wrap {width:80%;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .copy {flex-direction: column; display:flex;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .sidebarcontent {margin: 20px 0 0 0;max-width: 100%; order: 2; float:none;}
		.cu-section__simple-content.singletextrow .inner-wrap.hassidebar .textsection {order: 1;}
		
	}
	
	@media only screen and (min-width: 700px) {

		.two-column-text {columns:2; column-count:2; column-gap:40px;}
	}

	@media only screen and (max-width: 699px) {

		.two-column-text {columns:1; column-count:1; margin-left: 20px;}
	}

	@media only screen and (max-width: 599px) {

		.cu-section__simple-content.singletextrow .inner-wrap {width:calc(100% - 40px);}
	}

}