// Debug Styles
.debug {
	margin: 30px auto;
	padding: 25px;
	width: 90%;
	border: 1px solid #000;
	border-radius: 10px;
	background-color: #fff;
	color: #000;
	font-family: monospace;
	z-index: 9999;

	h1 {
		margin-bottom: 0;
	}

	h2 {
		margin-top: 20px;
		margin-bottom: 10px;
	}

	pre {
		margin-bottom: 24px;
	}

	.error {
		margin-bottom: 22px;
		padding: 15px;
		border: 3px solid red;
		border-radius: 10px;

		.theerror {
			font-size: 16px;
		}

		.stack {
			margin-bottom: 4px;
			margin-top: 10px;
			display: block;
			color: #777;
		}

		p {
			margin-bottom: 4px;
			line-height: 18px;
		}

		ol {
			margin: 0;
			font-size: 12px;
			color: #777;
			line-height: 16px;
		}
	}
	h2 a.show {
		color: #000;
		font-weight: bold;
		text-decoration: none;
	}
	.debug_config {
		display: none;
	}
	.error_vars {
		padding-top: 16px;
		font-size: 10px;
		color: #bbb;
	}
}