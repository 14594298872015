.cu-part__expanders {
	.expander {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.expander-header {
			background: $blue;
			position: relative;
			padding: 30px 105px 25px 30px;
			cursor: pointer;

			h3 {
				font-family: $sc_font;
				font-size: 22px;
				font-weight: 600;
				color: $white;
			}

			.toggle {
				position: absolute;
				top: 50%;
				right: 40px;
				transform: translateY(-50%);

				font-family: $c_font;
				font-size: 1rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $gold;

				&:before {
					content: 'View';
				}
			}

			&.active {
				.toggle:before {
					content: 'Close';
				}
			}

			@include non-touch {
				&:hover {
					.toggle {
						text-decoration: underline;
					}
				}
			}
		}

		.expander-body {
			display: none;
			padding: 60px 0;
			box-shadow: 0px 3px 6px rgba($black, 0.16);
			background: $white;

			.copy {
				width: 875px;
				margin: 0 auto;
			}
		}

		@include max-down(1160px) {
			.expander-body .copy {
				width: 80%;
			}
		}

		@include max-down(600px) {
			.expander-header h3 {
				font-size: 1rem;
			}

			.expander-body {
				padding: 35px 0;

				.copy {
					width: 90%;
				}
			}
		}
	}
}