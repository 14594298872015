.notice-shown .mega-nav {
	&.active {
		transform: translateY(147px);
	}
}

.mega-nav {
	display: flex;
	align-items: stretch;
	width: 100%;
	max-width: 1420px;
	position: fixed;
	top: 0;
	left: calc(50% - 710px);
	transform: translateY(-100%);
	z-index: 15;
	background: $white;
	overflow: hidden;

	&.active {
		transform: translateY(107px);
	}

	.left {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 550px;

		.image {
			width: 100%;
			height: 200px;
		}

		.bottom {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 200px;
			background: $gold;

			.button-wrap {
				margin-top: 25px;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;
		justify-content: center;
		width: calc(100% - 550px);

		nav {
			width: 100%;
			padding: 0 80px;

			ul {
				columns: 2;
			}

			li {
				margin-bottom: 25px;

				a {
					display: block;
					position: relative;
					padding-left: 22px;

					font-family: $c_font;
					font-weight: 700;
					font-size: 20px;
					text-decoration: none;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $dblue;

					&:before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						width: 12px;
						height: 12px;
						position: relative;
						top: -2px;
						left: -10px;
						background: url('../svg/close-gold.svg') center no-repeat;
						background-size: contain;
						transform: rotate(45deg);
					}

					&.active {
						background: $white;
						color: $blue;

						&:before {
							transform: rotate(0deg);
						}
					}
				}
			}
		}
	}

	@include max-down(1450px) {
		max-width: 100%;
		left: 0;
	}

	@include max-down(1200px) {
		display: none !important;
	}
}