// Colors
$lblue: #36CDF7;
$blue: #162B48;
$dblue: #081629;

$gold: #e8aa15;
$brown: #986F34;

$altgray: #DBDEDE;
$offgray: #A9AEAE;
$ogray: #BEC3C4;
$gray: #7B7F7F;
$lgray: #F2F3F3;

$black: #000000;
$white: #ffffff;