.footer-wrap {
	position: relative;
	z-index: 2;
	background: $blue;

	.footer {
		@include clearfix;
		width: 1415px;
		margin: 0 auto;
		padding: 60px 0 120px 0;

		.logo {
			@include hidden-text;
			display: block;
			width: 200px;
			height: 108px;
			float: left;
			background: url('../svg/logo-stacked-white.png') center no-repeat;
			background-size: contain;
		}

		.footer-right {
			width: 815px;
			float: right;

			.menus {
				@include clearfix;

				.menu-left {
					width: 235px;
					float: left;

					.footer-contact-menu {
						li {
							margin-bottom: 20px;

							&:last-child {
								margin-bottom: 0;
							}

							a {
								font-size: 0.875rem;
								font-weight: 700;
								letter-spacing: 0.02em;
								color: $gray;
								text-decoration: none;

								@include non-touch {
									transition: color .3s;

									&:hover {
										color: $gold;
									}
								}
							}
						}
					}
				}

				.menu-right {
					width: 515px;
					float: right;

					.footer-links-menu {
						ul {
							display: grid;
							grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
							row-gap: 20px;
							grid-row-gap: 20px;
							column-gap: 55px;
							grid-column-gap: 55px;
							grid-auto-flow: column;
						}

						li {
							a {
								font-size: 0.875rem;
								font-weight: 700;
								letter-spacing: 0.02em;
								color: $gray;
								text-decoration: none;

								@include non-touch {
									transition: color .3s;

									&:hover {
										color: $gold;
									}
								}
							}
						}
					}
				}
			}

			.mobile-logo {
				@include hidden-text;
				display: none;
				width: 200px;
				height: 108px;
				margin-top: 75px;
				background: url('../svg/logo-stacked-white.png') center no-repeat;
				background-size: contain;
			}

			.notice {
				margin-top: 55px;

				span {
					font-size: 0.777rem;
					font-weight: 700;
					color: $gray;
					text-decoration: none;
				}
			}

			.copyright {
				margin-top: 30px;

				span {
					font-size: 0.777rem;
					font-weight: 700;
					color: $gray;
					text-decoration: none;
				}
			}

			.crafted-by-tbx {
				margin-top: 30px;
			}
		}
	}

	@include max-down(1600px) {
		.footer {
			width: 90%;
		}
	}

	@include max-down(1200px) {
		.footer {
			.footer-right {
				width: calc(100% - 250px);

				.menus {
					.menu-right {
						width: calc(100% - 255px);
					}
				}
			}
		}
	}

	@include max-down(1000px) {
		.footer {
			.logo {
				display: none;
			}

			.footer-right {
				width: 100%;

				.mobile-logo {
					display: block;

				}
			}
		}
	}

	@include max-down(750px) {
		.footer {
			padding: 90px 0;
			
			.footer-right {
				text-align: center;
				.menus {
					.menu-left {
						width: 235px;
						float: none;
						margin: 0 auto;
						text-align: center;

						h2:before {
							margin-left: auto;
							margin-right: auto;
						}
					}

					.menu-right {
						display: none;
					}
				}

				.mobile-logo {
					margin: 55px auto 0 auto;
				}

				.notice {
					margin-top: 40px;
				}

				.copyright {
					margin-top: 20px;
				}

				.crafted-by-tbx {
					margin-top: 20px;

					img {
						display: inline-block;
					}
				}
			}
		}
	}
}