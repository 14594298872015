.page-template-page-general-template-6 {
	.page-background-wrap {
		padding-bottom: 260px;

		@include max-down(1160px) {
			padding-bottom: 200px;
		}

		@include max-down(750px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}
}