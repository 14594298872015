.cu-section__post-list {
	padding: 120px 0 175px 0;

	> .info {
		@include clearfix;
		@include section-line-before(60px, 30px);
		width: 1470px;
		margin: 0 auto;
		padding-left: 80px;

		h2 {
			float: left;
		}

		.button-wrap {
			float: right;
			padding-top: 10px;
		}
	}

	.post-list {
		width: 1470px;
		margin: 60px auto 0 auto;
		font-size: 0;
		text-align: center;

		&.tbx-animated {
			// .filter-list-item .image img {
			// 	opacity: 0;
			// }

			&.tbx-animated-siv-ft {
				.filter-list-item[data-transition-delay="0.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.1s);
				}
				.filter-list-item[data-transition-delay="0.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.2s);
				}
				.filter-list-item[data-transition-delay="0.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.3s);
				}
				.filter-list-item[data-transition-delay="0.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.4s);
				}
				.filter-list-item[data-transition-delay="0.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.5s);
				}
				.filter-list-item[data-transition-delay="0.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.6s);
				}
				.filter-list-item[data-transition-delay="0.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.7s);
				}
				.filter-list-item[data-transition-delay="0.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.8s);
				}
			}
		}

		.cu-card__post {
			display: inline-block;
			vertical-align: top;
			width: 345px;
			margin-right: 30px;
			margin-bottom: 30px;
			text-align: left;

			&:last-child {
				margin-right: 0;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}
		}
	}

	@include max-down(1600px) {
		> .info {
			width: 95%;
		}

		.post-list {
			width: 95%;

			.cu-card__post {
				width: calc(25% - calc(90px / 4));
			}
		}
	}

	@include max-down(1260px) {
		.post-list {
			.cu-card__post {
				width: calc(33.33333% - 20px);

				&:nth-child(4n) {
					margin-right: 30px;
				}

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(950px) {
		.post-list {
			margin-top: 75px;

			.cu-card__post {
				width: calc(50% - 15px);

				&:nth-child(3n) {
					margin-right: 30px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(750px) {
		> .info {
			@include section-line-before-break;
			padding-left: 0;
			text-align: center;

			&:before {
				margin-left: auto;
				margin-right: auto;
			}

			h2 {
				float: none;
			}

			.button-wrap {
				float: none;
				padding-top: 35px;
			}
		}
	}

	@include max-down(600px) {
		padding: 100px 0;

		> .info {
			width: calc(100% - 40px);
		}

		.post-list {
			width: calc(100% - 40px);

			.cu-card__post {
				width: 100%;
				margin-right: 0 !important;
			}
		}
	}
}