.cumulo-filter-list {

    .cu-section__filter-list {
        margin-bottom:50px;    
    }

    .cu-section__simple-content .inner-wrap, .content-beneath {
        width:100%; padding-bottom:10px;

		.linewrapper{
			border-bottom: solid 2px $gold; text-align: center; margin-left:auto; margin-right:auto; display: inline-block; padding-bottom: 0px; margin-bottom: 15px; text-align: center;
		}


        }

        @media only screen and (max-width: 1599px) {
            .cu-section__simple-content .inner-wrap, .contentbeneath {
                width: 90%;
            }
        }    
}