.page-template-page-next-steps {
	.cu-section__simple-content {
		.inner-wrap {
			.jump-nav {
				display: none;

				@include max-down(750px) {
					display: block;

					.mobile-toggle {
						display: inline-block;
					}
				}
			}
		}
	}

	.page-background-wrap {
		padding-top: 50px;
		padding-bottom: 290px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-top: 0;
		}
	}

	.cu-section__half {
		position: relative;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -70px;
			left: -165px;
			background: url('../svg/plus-pattern-gold.svg') center no-repeat; 
			background-size: contain;

			@include max-down(1160px) {
				bottom: 0;
				left: -300px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 585px;
			position: absolute;
			top: -75px;
			left: 10px;
			background: url('../svg/diamond-pattern-gray-5-column.svg') center no-repeat; 
			background-size: contain;

			@include max-down(1160px) {
				width: 400px;
				height: 499px;
				top: -50px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.triangle-pattern {
			display: block;
			width: 353px;
			height: 491px;
			position: absolute;
			top: -80px;
			left: 0;
			background: url('../svg/triangle-pattern-gray-wide.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				top: -25px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}
}