.page-template-page-quick-links-admissions {
	.cu-section__simple-content .inner-wrap {
		padding-bottom: 155px;
	}

	.page-background-wrap {
		padding-bottom: 305px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}

	.quick-links-wrap {
		position: relative;
		z-index: 2;
		margin-top: -70px;

		.inner-wrap {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
			row-gap: 20px;
			width: 1170px;
			margin: 0 auto;
		}

		.quick-link {
			background: $white;
			box-shadow: 6px 6px 24px rgba($black, 0.16);
			padding: 45px 0;

			.inner-content-wrap {
				width: calc(100% - 100px);
				height: 100%;
				position: relative;
				margin: 0 auto;
				padding-bottom: 75px;

				.button-wrap {
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}

		@include max-down(1300px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			.inner-wrap {
				grid-template-columns: 1fr;
			}
		}

		@include max-down(600px) {
			.quick-link .inner-content-wrap {
				width: 80%;
			}
		}
	}
}