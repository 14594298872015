.cu-section__form .gform_wrapper {
	margin: 0;

	.gform_body {
		padding-bottom: 0;

		.gfield {
			padding: 0;
			margin-bottom: 30px;
			overflow: visible;

			.gfield_label {
				display: block;
				padding: 0;
				margin-bottom: 10px;

				font-family: $c_font;
				font-size: 1rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;
			}

			&.gsection {
				text-align: center;

				.gsection_title {
					padding-top: 50px;
					padding-bottom: 25px;
					border-bottom: 3px solid $gold;

					font-family: $ec_font;
					font-size: 30px;
					font-weight: 600;
					line-height: 0.92em;
					letter-spacing: 0.18em;
					text-transform: uppercase;
					color: $blue;
				}

				.gsection_description {
					font-size: 1rem;
					line-height: 1.8em;
					letter-spacing: 0.01em;
				}
			}

			.ginput_complex {
				> span {
					display: block;
					margin-bottom: 15px;

					&:last-child {
						margin-bottom: 0;
					}

					label {
						text-transform: none;
						font-family: $sc_font;
						font-size: 0.875rem;
					}
				}
			}

			.gfield_checkbox,
			.gfield_radio {
				li {
					margin-bottom: 10px;

					input[type="checkbox"],
					input[type="radio"] {
						display: none;
					}

					label {
						display: block;
						position: relative;
						padding-left: 25px;

						font-family: $n_font;
						font-size: 1rem;
						text-transform: none;
						color: $black;

						&:before {
							content: '';
							display: block;
							width: 16px;
							height: 16px;
							position: absolute;
							top: 2px;
							left: 0;
							border: 2px solid $blue;
						}
					}

					input[type="checkbox"]:checked + label:before,
					input[type="radio"]:checked + label:before {
						background: $blue;
					}
				}
			}

			.gfield_radio {
				li label:before {
					border-radius: 50%;
				}
			}
		}

		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="tel"],
		select,
		textarea {
			appearance: none;
			width: 100%;
			height: 44px;
			border: 2px solid $blue;
			border-radius: 0;
			outline: 0;
			background: $white;
			padding: 0 15px;
			margin: 0;

			font-family: $n_font;
			font-size: 1rem;
			text-transform: none;
			color: $black;
		}

		select {
			padding-right: 30px;
			background: $white url('../svg/select-arrow-blue.svg') right 15px center no-repeat;
			background-size: 12px 6px;
		}

		textarea {
			height: 240px;
		}
	}

	.gform_footer {
		padding: 0;
		margin-top: 60px;
		text-align: center;

		.gform_button {
			min-width: 280px;
			padding: 20px 25px;
			background: $gold;

			@include non-touch {
				&:hover {
					background: $blue;
					color: $gold;
					border-color: $blue;
				}
			}
		}
	}
}