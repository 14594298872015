.cu-section__filter-list {
	width: 1470px;
	margin: 0 auto 130px auto;

	&.table {
		@include max-down(500px) {
			width: 100%;
		}

		.filter-list-table {
			width: 100%;

			.table-header {
				display: flex;
				align-items: stretch;
				width: 100%;

				.table-heading {
					padding: 25px 40px;
					background: $blue;

					font-family: $c_font;
					font-size: 20px;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					text-align: left;
					color: $white;

					&:first-child {
						width: 60%;
					}

					&:last-child {
						width: 40%;
					}

					@include max-down(750px) {
						padding: 25px 15px;
					}

					@include max-down(500px) {
						&:first-child {
							width: 100%;
						}

						&:last-child {
							display: none;
						}
					}
				}
			}

			.table-body {
				.no-posts {
					padding-top: 15px;
				}

				.filter-list-item {
					display: flex;
					align-items: stretch;
					width: 100%;
					margin: 0;
					text-align: left;

					&:nth-child(2n) {
						.item-heading,
						.item-content {
							background: $lgray;
						}
					}
				}

				.filter-list-item.indent {
					.item-heading {
						padding-left: 60px;

						font-weight: 500;

						@include max-down(750px) {
							padding-left: 30px;
						}
					}
				}

				.item-heading {
					display: block;
					width: 60%;
					padding: 15px 40px;

					font-family: $c_font;
					font-size: 20px;
					font-weight: 600;
					color: $dblue;

					a {
						display: block;
						color: inherit;
						text-decoration: none;

						@include non-touch {
							transition: color .3s;

							&:hover {
								color: $gold;
							}
						}
					}

					@include max-down(750px) {
						padding: 15px;
					}

					@include max-down(500px) {
						width: 100%;
					}
				}

				.item-content {
					display: block;
					width: 40%;
					padding: 12px 40px 15px;

					font-family: $c_font;
					font-size: 20px;
					font-weight: 500;
					color: $dblue;

					span {
						display: inline-block;
						vertical-align: middle;
						position: relative;
						padding-right: 20px;
						margin-right: 20px;

						&:after {
							content: '';
							display: block;
							width: 2px;
							height: 30px;
							position: absolute;
							top: 0;
							right: 0;
							background: $gold;
						}

						&:last-child {
							&:after {
								display: none;
							}
						}
					}

					@include max-down(750px) {
						padding: 12px 15px 15px;

						span {
							display: block;
							padding-right: 0;
							margin-right: 0;
							margin-bottom: 10px;

							&:after {
								display: none;
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					@include max-down(500px) {
						display: none;
					}
				}
			}
		}
	}

	.filter-list-item {
		width: 345px;
		margin-bottom: 30px;

		&.cu-card__faculty {
			height: 500px;

			.info {
				position: relative;
				padding-bottom: 92px;

				.button-wrap {
					position: absolute;
					bottom: 30px;
					left: 25px;
					margin-top: 0;
				}
			}

			@include max-down(1350px) {
				height: 540px;
			}

			@include max-down(1250px) {
				height: 500px;
			}

			@include max-down(650px) {
				height: 540px;
			}

			@include max-down(600px) {
				height: auto;

				.info {
					padding-bottom: 30px;

					.button-wrap {
						position: static;
						margin-top: 20px;
					}
				}
			}
		}
	}

	&.has-view-more {
		margin-bottom: 50px;
	}

	@include max-down(1600px) {
		width: 90%;

		.filter-list-item {
			width: calc(25% - calc(90px / 4));

			&.featured {
				width: calc(50% - calc(90px / 4)) !important;
			}
		}
	}

	@include max-down(1250px) {
		.filter-list-item {
			width: calc(33.33333% - 20px);

			&.featured {
				width: 100% !important;
			}
		}
	}

	@include max-down(1000px) {
		.filter-list-item {
			width: calc(50% - 15px);
		}
	}

	@include max-down(600px) {
		width: calc(100% - 40px);

		.filter-list-item {
			width: 100%;
		}
	}
}

.filter-list-more {
	width: calc(100% - 40px);
	margin: 0 auto;
	padding-bottom: 130px;
	text-align: center;
}