.cu-section__hero {
	position: relative;
	z-index: 2;
	padding-top: 137px;

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 680px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		background: $blue;
		background: linear-gradient(45deg, $blue 5%, $dblue 65%); 
	}

	.inner-wrap {
		width: 1780px;
		height: 710px;
		position: relative;
		z-index: 2;
		margin: 0 0 0 auto;

		.image,
		.tablet-image,
		.mobile-image {
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
		}

		.image {
			display: block;
		}

		.tablet-image {
			display: none;
			z-index: 2;
		}

		.mobile-image {
			display: none;
			z-index: 3;
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 4;
			opacity: 0.45;
			background: $gold;
			background: linear-gradient(45deg, $gold 5%, $blue 85%); 
		}

		.mobile-back-wrap {
			display: none;
			position: absolute;
			top: 25px;
			left: 20px;
			z-index: 5;
		}

		.inner-content-wrap {
			@include section-line-before(60px, 23px);
			width: calc(100% - 310px);
			z-index: 5;
			margin: 0 auto;
			padding-left: 80px;
			padding-bottom: 75px;

			.button-wrap {
				margin-top: 40px;
			}
		}
	}

	@include max-down(1921px) {
		.inner-wrap {
			width: 95%;
		}
	}

	@include max-down(1400px) {
		.inner-wrap .inner-content-wrap {
			width: 80%;
		}
	}

	@include max-down(1300px) {
		.inner-wrap {
			height: 640px;
		}
	}

	@include max-down(1160px) {
		padding-top: 118px;

		&:before {
			height: 640px;
		}

		.inner-wrap {
			.image {
				display: none;
			}

			.tablet-image {
				display: block;
			}

			.inner-content-wrap {
				width: 90%;
			}
		}
	}

	@include max-down(1000px) {
		.inner-wrap .mobile-back-wrap {
			display: block;
		}
	}

	@include max-down(750px) {
		.inner-wrap {
			width: calc(100% - 20px);
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			.tablet-image {
				display: none;
			}

			.mobile-image {
				display: block;
			}
			
			.inner-content-wrap {
				@include section-line-before-break;
				width: calc(100% - 40px);
				padding-left: 0;
				padding-bottom: 45px;
			}
		}
	}
}