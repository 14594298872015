.tbx-module__styled-select {
	position: relative;

	.toggle {
		display: block;
		width: 100%;
		height: auto;
		position: relative;
		padding: 15px 25px;
		margin: 0;
		border: 1px solid #000;

		color: #000;
		text-decoration: none;

		&:after {
			content: '';
			display: block;
			width: 16px;
			height: 8px;
			position: absolute;
			top: 50%;
			right: 25px;
			transform: translateY(-50%);
			background: url('../svg/select-arrow.svg') center no-repeat;
			background-size: contain;
		}

		&.active {
			&:after {
				transform: translateY(-50%) rotate(180deg);
			}
		}
	}

	.styled-select {
		display: none;
		width: 100%;
		height: auto;
		position: absolute;
		top: 100%;
		left: 0;
		border: 1px solid #000;
		border-top: 0;
		background: #fff;

		li {
			a {
				display: block;
				width: 100%;
				height: auto;
				padding: 15px 25px;

				color: #000;
				text-decoration: none;

				&:hover {
					background: #000;
					color: #fff;
				}
			}
		}
	}

	.mobile-select {
		appearance: none;
		display: none;
		width: 100%;
		height: auto;
		position: relative;
		padding: 15px 45px 15px 25px;
		margin: 0;
		border: 1px solid #000;
		background: url('../svg/select-arrow.svg') right 25px center no-repeat;
		background-size: 16px 8px;

		color: #000;
		text-decoration: none;
	}
}

@media screen and (max-width: 600px) {
	.tbx-module__styled-select.has-mobile-select .toggle,
	.tbx-module__styled-select.has-mobile-select .styled-select {
		display: none;
	}

	.tbx-module__styled-select.has-mobile-select .mobile-select {
		display: block;
	}
}