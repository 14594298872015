.page-template-page-general-template-8 {
	.cu-section__half:not(.flipped) {
		position: relative;
		z-index: 2;
	}

	.page-background-wrap {
		padding-bottom: 275px;

		@include max-down(750px) {
			padding-bottom: 75px;
		}
	}

	.cu-section__contact-bar {
		margin-top: 110px;

		@include max-down(1000px) {
			margin-top: 100px;
		}

		@include max-down(750px) {
			margin-top: 75px;
		}
	}
}