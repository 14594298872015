.cu-section__faculty-list {
	padding: 120px 0 175px 0;

	> .info {
		width: 800px;
		margin: 0 auto;
	}

	.faculty-list {
		width: 1470px;
		margin: 120px auto 0 auto;
		font-size: 0;
		text-align: center;

		&.tbx-animated {
			.filter-list-item .image img {
				opacity: 0;
			}

			&.tbx-animated-siv-ft {
				.filter-list-item[data-transition-delay="0.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.1s);
				}
				.filter-list-item[data-transition-delay="0.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.2s);
				}
				.filter-list-item[data-transition-delay="0.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.3s);
				}
				.filter-list-item[data-transition-delay="0.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.4s);
				}
				.filter-list-item[data-transition-delay="0.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.5s);
				}
				.filter-list-item[data-transition-delay="0.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.6s);
				}
				.filter-list-item[data-transition-delay="0.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.7s);
				}
				.filter-list-item[data-transition-delay="0.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.8s);
				}
				.filter-list-item[data-transition-delay="0.9"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 0.9s);
				}
				.filter-list-item[data-transition-delay="1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1s);
				}
				.filter-list-item[data-transition-delay="1.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.1s);
				}
				.filter-list-item[data-transition-delay="1.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.2s);
				}
				.filter-list-item[data-transition-delay="1.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.3s);
				}
				.filter-list-item[data-transition-delay="1.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.4s);
				}
				.filter-list-item[data-transition-delay="1.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.5s);
				}
				.filter-list-item[data-transition-delay="1.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.6s);
				}
				.filter-list-item[data-transition-delay="1.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.7s);
				}
				.filter-list-item[data-transition-delay="1.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.8s);
				}
				.filter-list-item[data-transition-delay="1.9"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 1.9s);
				}
				.filter-list-item[data-transition-delay="2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2s);
				}
				.filter-list-item[data-transition-delay="2.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.1s);
				}
				.filter-list-item[data-transition-delay="2.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.2s);
				}
				.filter-list-item[data-transition-delay="2.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.3s);
				}
				.filter-list-item[data-transition-delay="2.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.4s);
				}
				.filter-list-item[data-transition-delay="2.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.5s);
				}
				.filter-list-item[data-transition-delay="2.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.6s);
				}
				.filter-list-item[data-transition-delay="2.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.7s);
				}
				.filter-list-item[data-transition-delay="2.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.8s);
				}
				.filter-list-item[data-transition-delay="2.9"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 2.9s);
				}
				.filter-list-item[data-transition-delay="3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3s);
				}
				.filter-list-item[data-transition-delay="3.1"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.1s);
				}
				.filter-list-item[data-transition-delay="3.2"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.2s);
				}
				.filter-list-item[data-transition-delay="3.3"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.3s);
				}
				.filter-list-item[data-transition-delay="3.4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.4s);
				}
				.filter-list-item[data-transition-delay="3.5"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.5s);
				}
				.filter-list-item[data-transition-delay="3.6"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.6s);
				}
				.filter-list-item[data-transition-delay="3.7"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.7s);
				}
				.filter-list-item[data-transition-delay="3.8"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.8s);
				}
				.filter-list-item[data-transition-delay="3.9"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 3.9s);
				}
				.filter-list-item[data-transition-delay="4"] .image img {
					@include fadeIn($duration: 0.7s, $delay: 4s);
				}
			}
		}

		.cu-card__faculty {
			display: inline-block;
			vertical-align: top;
			width: 345px;
			height: 500px;
			margin-right: 30px;
			margin-bottom: 30px;
			text-align: left;

			&:last-child {
				margin-right: 0;
			}

			&:nth-child(4n) {
				margin-right: 0;
			}

			.info {
				position: relative;
				padding-bottom: 92px;

				.button-wrap {
					position: absolute;
					bottom: 30px;
					left: 25px;
					margin-top: 0;
				}
			}
		}
	}

	@include max-down(1600px) {
		.faculty-list {
			width: 95%;

			.cu-card__faculty {
				width: calc(25% - calc(90px / 4));
			}
		}
	}


	@include max-down(1350px) {
		.faculty-list .cu-card__faculty {
			height: 540px;
		}
	}

	@include max-down(1260px) {
		.faculty-list {
			.cu-card__faculty {
				width: calc(33.33333% - 20px);
				height: 500px;

				&:nth-child(4n) {
					margin-right: 30px;
				}

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(950px) {
		> .info {
			width: 90%;
		}

		.faculty-list {
			margin-top: 75px;

			.cu-card__faculty {
				width: calc(50% - 15px);

				&:nth-child(3n) {
					margin-right: 30px;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(650px) {
		.faculty-list .cu-card__faculty {
			height: 540px;
		}
	}

	@include max-down(600px) {
		padding: 100px 0;

		> .info {
			width: calc(100% - 80px);
		}

		.faculty-list {
			width: calc(100% - 40px);

			.cu-card__faculty {
				width: 100%;
				margin-right: 0 !important;
				height: auto;

				.info {
					padding-bottom: 30px;

					.button-wrap {
						position: static;
						margin-top: 20px;
					}
				}
			}
		}
	}
}