.page-template-page-components, .program-template-page-components {
	.wrap.Icon-List {
background-color: $lgray; padding-top:0px; padding-bottom: 20px; margin-top:40px;
		.cu-section__simple-content {background-color:inherit;}
	}
.listcomponentwrap {
.cu-section__simple-content.no-bg.inverted {max-width:1200px; margin-left:auto; margin-right:auto; margin-bottom:20px; background-color:#13294b;
.headline {color:#ffffff;}
.copy {color:#ffffff;}
}
}

	
.expanders-wrap.listcomponentwrap.wrap.Accordion.WhiteBar .cu-part__expanders.accordion{
		.expander:last-of-type{border-bottom: solid 1px $blue;}
		.expander {margin-bottom:0px;
			.expander-header:hover .toggle {text-decoration:none;}
			.expander-header {background-color: $white; border-top: solid 1px $blue;}
			
			.expander-header h3 {color: $blue;}
			.expander-header.active {background-color:$blue;}
			.expander-header.active h3 {color: $white;}
			.expander-body {-webkit-box-shadow: none; box-shadow: none; padding: 20px 0px;}
			.expander-header .toggle {top:45%;}
			.expander-header .toggle:before {content: "+"; font-size:30px; text-decoration:none;}
			.expander-header.active .toggle:before {content: "-";}
		}
	}


.wrapTabs .cu-section__simple-content {margin-top:30px;}
.cu-section__simple-content .inner-wrap.forTabs {padding: 15px 0 15px;}
	.cu-section__simple-content .inner-wrap.forIcon-List {padding-bottom:15px;}
	.accordionholder {margin-bottom: 30px; padding:0px 20px;}
	.cu-part__expanders {width:100%; margin-left:auto; margin-right:auto; max-width:1170px;}
	.copy p {margin-bottom: 15px;}
	.numberlistblock {
		padding: 0px 0px 145px 0px;
		h2 {font-weight:800; font-family: $sc_font; margin-bottom:.55em; font-size:28px; color:$blue;}
		.number {color:$blue;}
	}

	.tabcomponent {display: flex; max-width:1240px; margin-left:auto; margin-right:auto;
		.cu-part__expanders.tabs .expander {margin-bottom:0px;}
		.cu-part__expanders.tabs {background-color:$lgray; margin-left:auto; margin-right:auto; border-bottom: solid 1px $blue;}
		.cu-part__expanders.tabs .expander-body {background-color:$lgray;}
		.cu-part__expanders.tabs .expander-body .copy {width:inherit; padding: 10px 40px;
		 	h1,h2,h3,h4,h5,h6 {text-transform:none; letter-spacing:normal;}
			.headline {text-transform:none; letter-spacing:normal;}
		}
		
		.tablinks {display:block; padding: 10px 20px; width:100%; color:$blue; }
		.expander {
			.expander-header:hover .toggle {text-decoration:none;}
			.expander-header {background-color: $white; border-top: solid 1px $blue;}
			.expander-header h3 {color: $blue;}
			.expander-header.active {background-color:$blue;}
			.expander-header.active h3 {color: $white;}
			.expander-body {-webkit-box-shadow: none; box-shadow: none; padding: 20px 0px;}
			.expander-header .toggle:before {content: "+"; font-size:30px; top:45px; text-decoration:none;}
			.expander-header.active .toggle:before {content: "-";}
		}
	}

	.tabcomponent.top {display:block;
		.tabcontrols {width:100%; max-width:1170px; margin-left:auto; margin-right:auto; display:flex; justify-content:center;}
		.cu-part__expanders.tabs{width:100%;} 
		.tabhighlight {font-weight:bold; background-color:$lgray;}
		.tablinks {text-align:center; padding: 8px 18px; max-width:300px;}
	}

	.tabcomponent.left {
	.tabhighlight {border-left:solid 4px $gold; background-color:$lgray; font-weight:bold;}
	}

	.iconlistsubcontent {margin-top:30px;}


@media(min-width:960px){
	.cu-part__expanders.tabs .expander-header {display:none;}
	.tabcontrols {width:30%; margin:0px; display:block;}
	.tabcomponent .cu-part__expanders.tabs {border-bottom: none; width:70%;}
	.cu-section__iconlist {
		display:flex; align-items:stretch; justify-content:center; 
		.iconlistcomponent {columns:2; display:flex;}
		.iconlistitem {width:49%;}
		.iconlistsidebar {border-right:solid 2px $blue;}
	}
}
@media(max-width:959px){
	.cu-part__expanders.tabs .expander-header {display:block; width:100%;}
	.tabcomponent.top .tabcontrols, .tabcontrols {display:none;}
	.cu-section__iconlist {display:block;
	 .iconlistcomponent {display:block; columns: 1;}
	 .iconlistitem {max-width:100%; margin-left:auto; margin-right:auto;}
	 .iconlistsidebar {margin-bottom:15px; border-right:none;}
	}
}

.cu-section__iconlist {
	max-width:1240px; margin-left:auto; margin-right:auto;

	.iconlistitem {display:flex; align-items:center;}
	.iconlistcomponent {flex-direction:row; flex-wrap:wrap;}
	.sk-iconwrap {padding:10px;}
	.sk-iconwrap {padding:10px;}
	.sk-iconwrap img {max-width:60px; max-height:max-content;}
	.iconlistitem .copy {padding:10px;}
	.iconlistsidebar {min-width:15%; display:flex; flex-flow:column; align-items:center; justify-content:center; margin-right:15px;}
}







}