.single-division {
	.programs-pattern-wrap {
		position: relative;
		padding: 160px 0 195px 0;

		.triangle-pattern {
			display: block;
			width: 556px;
			height: 690px;
			position: absolute;
			top: -55px;
			right: -65px;
			background: url('../svg/triangle-pattern-spread.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding: 100px 0;

			.triangle-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding: 75px 0;
		}
	}

	.programs-wrap {
		width: 1240px;
		position: relative;
		z-index: 2;
		margin: 0 auto;
		background: $blue;

		.inner-wrap {
			width: calc(100% - 370px);
			margin: 0 auto;
			padding: 60px 0;

			.programs {
				@include clearfix;
				margin-top: 60px;
			}

			.majors,
			.minors {
				@include clearfix;
				width: 400px;
				float: left;

				h3 {
					width: 125px;
					float: left;
				}

				ul {
					width: calc(100% - 125px);
					float: right;
					padding-top: 8px;

					li {
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}

						a {
							font-family: $c_font;
							font-size: 1rem;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							text-decoration: none;
							color: $white;
						}
					}
				}
			}

			.minors {
				float: right;
			}
		}

		@include max-down(1400px) {
			width: 90%;

			.inner-wrap {
				width: calc(100% - 200px);

				.majors,
				.minors {
					width: 47.5%;
				}
			}
		}

		@include max-down(1000px) {
			.inner-wrap {
				.majors,
				.minors {
					h3,
					ul {
						width: 100%;
						float: none;
						padding-top: 0;
					}

					h3 {
						margin-bottom: 25px;
					}
				}
			}
		}

		@include max-down(750px) {
			.inner-wrap {
				width: 90%;
			}
		}

		@include max-down(600px) {
			width: calc(100% - 40px);

			.inner-wrap {
				.majors,
				.minors {
					width: 100%;
					float: none;
					text-align: center;
				}

				.minors {
					margin-top: 55px;
				}
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 340px;
	}

	.alumni-story {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 660px;
			position: absolute;
			bottom: -75px;
			left: 10px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1160px) {
			.diamond-pattern {
				left: -100px;
				bottom: -125px;
			}
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__half.flipped {
		position: relative;
		z-index: 2;
	}

	.testimonial-wrap {
		position: relative;
		z-index: 1;
		padding-top: 100px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: -305px;
			right: -55px;
			transform: rotate(-90deg);
			background: url('../svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1160px) {
			.plus-pattern {
				right: -265px;
			}
		}

		@include max-down(1000px) {
			padding-top: 0;

			.plus-pattern {
				display: none;
			}
		}
	}

	.cu-section__stats {
		padding-top: 170px;
		padding-bottom: 0;
	}
}