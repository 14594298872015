.single-plo {
	.outcomes-wrap {
		position: relative;

		.image-1-wrap {
			width: 280px;
			height: 420px;
			position: absolute;
			top: 235px;
			left: 75px;

			.triangle-pattern {
				display: block;
				width: 383px;
				height: 680px;
				position: absolute;
				top: -45px;
				left: -135px;
				background: url('../svg/triangle-pattern-gray-spread-small.svg') center no-repeat;
				background-size: contain;
			}

			.image-1 {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
			}
		}

		.image-2-wrap {
			width: 280px;
			height: 420px;
			position: absolute;
			bottom: 855px;
			right: 60px;

			.diamond-pattern {
				display: block;
				width: 469px;
				height: 657px;
				position: absolute;
				top: 140px;
				right: -290px;
				background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
				background-size: contain;
			}

			.image-2 {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
			}
		}

		.cu-section__simple-content .inner-wrap {
			padding-top: 120px;
			padding-bottom: 0;
		}

		.cu-section__number-list {
			padding-top: 70px;
			padding-bottom: 250px;
		}

		@include max-down(1600px) {
			.image-1-wrap {
				left: 0;
			}

			.image-2-wrap {
				right: 0;
			}
		}

		@include max-down(1400px) {
			.image-1-wrap,
			.image-2-wrap {
				display: none;
			}
		}

		@include max-down(1000px) {
			.cu-section__simple-content .inner-wrap {
				padding-top: 100px;
			}

			.cu-section__number-list {
				padding-bottom: 100px;
			}
		}

		@include max-down(600px) {
			.cu-section__simple-content .inner-wrap {
				padding-top: 75px;
			}

			.cu-section__number-list {
				padding-bottom: 75px;
			}
		}
	}

	.background-wrap {
		position: relative;
		padding-bottom: 135px;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 450px);
			position: absolute;
			bottom: 0;
			left: 0;
			background: $lgray;
		}

		.testimonial-wrap {
			position: relative;
			z-index: 2;
		}

		.resources-wrap {
			position: relative;
			z-index: 2;

			.cu-section__simple-content .inner-wrap {
				padding-top: 120px;
			}

			.cu-part__expanders {
				width: 1170px;
				margin: 0 auto;
			}
		}

		@include max-down(1300px) {
			.resources-wrap .cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.resources-wrap .cu-section__simple-content .inner-wrap {
				padding-top: 100px;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;

			.resources-wrap .cu-section__simple-content .inner-wrap {
				padding-top: 75px;
			}

			.resources-wrap .cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}
}