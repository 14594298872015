.cu-section__progress-bar {
	display: block;
	max-width: 1920px;
	height: 4px;
	position: fixed;
	width: 100%;
	top: 0;
	right: calc((100% - 1920px)/2);
	z-index: 50;
	background: linear-gradient(to right, $gold var(--scroll), transparent 0);
	background-repeat: no-repeat;

	@include max-down(1921px) {
		right: 0;
	}
}