.page-template-page-hub-admissions {
	.page-jump-nav {
		display: none;
		background: $lgray;
		padding: 20px 0;
		text-align: center;

		.title {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;

			font-family: $c_font;
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: $blue;
		}

		.mobile-toggle {
			appearance: none;
			display: inline-block;
			vertical-align: middle;
			width: 220px;
			position: relative;
			padding: 10px 30px 12px 16px;
			border: 2px solid $gold;
			overflow: hidden;
			white-space: nowrap;
			background: $lgray url('../svg/jump-nav-arrow-blue.svg') right 10px center no-repeat;
			background-size: 12px 10px;

			font-family: $c_font;
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.08em;
			color: $blue;
			text-align: left;
			text-overflow: ellipsis;
		}

		@include max-down(750px) {
			display: block;
		}
	}

	.outcomes-wrap {
		position: relative;

		.image-1-wrap {
			width: 280px;
			height: 420px;
			position: absolute;
			top: 235px;
			left: 75px;

			.triangle-pattern {
				display: block;
				width: 383px;
				height: 620px;
				position: absolute;
				top: -45px;
				left: -135px;
				background: url('../svg/triangle-pattern-gray-spread-small.svg') top center no-repeat;
				background-size: 383px 680px;
			}

			.image-1 {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
			}
		}

		.image-2-wrap {
			width: 280px;
			height: 420px;
			position: absolute;
			bottom: 190px;
			right: 60px;

			.diamond-pattern {
				display: block;
				width: 469px;
				height: 412px;
				position: absolute;
				top: 140px;
				right: -290px;
				background: url('../svg/diamond-pattern-gold-5-column.svg') top center no-repeat;
				background-size: 469px 657px;
			}

			.image-2 {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 2;
			}
		}

		.cu-section__simple-content {
			.inner-wrap {
				width: 800px !important;
				padding-top: 120px;
				padding-bottom: 0;

				.side-jump-nav-wrap {
					position: relative;

					.copy {
						float: none;
						width: 100%;
					}

					.right {
						position: absolute;
						top: -70px;
						right: -310px;
					}
				}
			}
		}

		.cu-section__number-list {
			padding-top: 70px;
			padding-bottom: 120px;
		}

		@include max-down(1600px) {
			.image-1-wrap {
				left: 0;
			}

			.image-2-wrap {
				right: 0;
			}
		}

		@include max-down(1400px) {
			.image-1-wrap,
			.image-2-wrap {
				display: none;
			}

			.cu-section__simple-content {
				.inner-wrap {
					.side-jump-nav-wrap {
						.copy {
							float: left;
							width: calc(100% - 300px);
						}

						.right {
							position: static;
						}
					}
				}
			}
		}

		@include max-down(1000px) {
			.cu-section__simple-content .inner-wrap {
				width: 90% !important;
				padding-top: 100px;
			}

			.cu-section__number-list {
				padding-bottom: 100px;
			}
		}

		@include max-down(750px) {
			.cu-section__simple-content {
				.inner-wrap {
					.side-jump-nav-wrap {
						.copy {
							width: 100%;
							float: none;
						}

						.right {
							display: none;
						}
					}
				}
			}
		}

		@include max-down(600px) {
			.cu-section__simple-content .inner-wrap {
				padding-top: 75px;
			}

			.cu-section__number-list {
				padding-bottom: 75px;
			}
		}
	}

	.cu-section__half {
		&.first {
			margin-top: 100px;

			@include max-down(1000px) {
				margin-top: 0;
			}
		}

		.plus-pattern {
			display: block;
			width: 494px;
			height: 538px;
			position: absolute;
			bottom: 100px;
			left: 0;
			background: url('../svg/plus-pattern-small-gold.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				bottom: 0;
				left: -300px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 585px;
			position: absolute;
			top: 20px;
			left: 10px;
			background: url('../svg/diamond-pattern-gray-5-column.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				width: 400px;
				height: 499px;
				top: -50px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}

	.testimonial-wrap {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 469px;
			height: 445px;
			position: absolute;
			bottom: 115px;
			left: 10px;
			z-index: 1;
			background: url('../svg/diamond-pattern-gold-5-column.svg') top center no-repeat;
			background-size: 469px 585px;
		}

		.cu-section__testimonial {
			z-index: 2;
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__news-feed {
		padding-bottom: 115px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}

	.page-background-wrap {
		padding-bottom: 175px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}
}