.error404 {
	.suggestions-wrap {
		padding: 85px 0 265px;

		.inner-content-wrap {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 50px;
			width: 1160px;
			margin: 0 auto;

			li {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include max-down(1300px) {
			.inner-content-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			.inner-content-wrap {
				grid-template-columns: 1fr;
				row-gap: 35px;
			}
		}

		@include max-down(750px) {
			padding-bottom: 210px;
		}
	}
}