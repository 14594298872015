.cu-card__post {
	height: 500px;
	background: $white;
	overflow: hidden;

	&.featured {
		width: 720px !important;
		background: $blue;

		a {
			@include clearfix;
		}

		.image {
			width: 420px;
			height: 100%;
			margin: 0 0 0 auto;
		}

		.info {
			width: calc(100% - 420px);
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $blue;

			.category {
				display: block;
				position: absolute;
				top: 30px;
				left: 30px;

				font-family: $sc_font;
				font-size: 0.66rem;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.02em;
				color: $offgray;
			}
		}
	}

	&.no-image {
		.info {
			height: 100%;

			.category {
				display: block;
				position: absolute;
				top: 30px;
				left: 30px;

				font-family: $sc_font;
				font-size: 0.66rem;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.02em;
				color: $offgray;
			}

			.inner-content-wrap {
				position: absolute;
				bottom: 25px;

				.button-wrap {
					width: 100%;
					position: static;
					margin-top: 38px;
				}
			}
		}
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
		text-decoration: none;
	}

	.image {
		width: 100%;
		height: 240px;
		position: relative;
		background: $lgray;

		.picture {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
		}

		.category {
			display: block;
			position: absolute;
			top: 30px;
			left: 30px;
			z-index: 2;

			font-family: $sc_font;
			font-size: 0.66rem;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			color: $white;
			text-shadow: 2px 2px 3px $black;
		}
	}

	.info {
		width: 100%;
		height: calc(100% - 240px);
		position: relative;
		background: $lgray;
		padding: 25px 30px 92px 30px;

		.date {
			display: block;
			margin-bottom: 15px;
			font-family: $sc_font;
			font-size: 0.92rem;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.02em;
			color: $gold;

			&:before {
				content: '';
				display: block;
				width: 30px;
				height: 2px;
				margin-bottom: 10px;
				background: $gold;
			}

			sup {
				display: inline-block;
				vertical-align: top;
				padding-top: 1px;
				font-size: 0.6em;
			}
		}

		h3 {
			line-height: 0.875em;
		}

		.button-wrap {
			width: calc(100% - 60px);
			position: absolute;
			bottom: 25px;
			left: 30px;
		}
	}

	@include non-touch {
		transition: box-shadow .3s;

		&:hover {
			box-shadow: 6px 6px 24px rgba($black, 0.16);

			.btn {
				background: $gold;
				border-color: $gold;
				color: $white;
			}
		}
	}

	@include max-down(1600px) {
		&.featured {
			.image {
				width: 45%;
			}

			.info {
				width: 55%;
			}
		}
	}

	@include max-down(1350px) {
		height: 540px;
	}

	@include max-down(1260px) {
		height: 500px;
	}

	@include max-down(1160px) {
		box-shadow: 0 10px 15px -6px rgba($black, 0.5);
		transition: box-shadow .3s;

		@include non-touch {
			&:hover {
				box-shadow: 0 10px 15px -6px rgba($black, 0.5);
			}
		}
		
		&:active {
			box-shadow: none;
		}
	}

	@include max-down(650px) {
		height: 540px;
	}

	@include max-down(600px) {
		height: auto;

		&.featured {
			.image {
				display: block;
				width: 100%;
				height: 240px;
				margin: 0;
			}

			.info {
				width: 100%;
				height: auto;
				position: static;

				.category {
					position: static;
					margin-bottom: 35px;
				}
			}
		}

		&.no-image {
			.info {
				padding-bottom: 30px;

				.category {
					position: static;
					margin-bottom: 35px;
				}

				.inner-content-wrap {
					position: static;
				}
			}
		}

		.info {
			height: auto;
		}
	}
}