.cu-section__share-links {
	padding: 100px 0;
	text-align: center;
	font-size: 0;

	h2 {
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
	}

	ul {
		display: inline-block;
		vertical-align: middle;
		padding-top: 5px;
	}

	li {
		display: inline-block;
		vertical-align: middle;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	a {
		display: block;

		&.facebook,
		&.linkedin,
		&.rss {
			width: 24px;
			height: 24px;
		}

		&.twitter {
			width: 24px;
			height: 20px;
		}

		&.email {
			width: 27px;
			height: 16px;
		}
	}
}