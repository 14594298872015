.page-template-page-academics {
	.background-wrap {
		position: relative;
		margin-top: 30px;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 450px);
			position: absolute;
			top: 0;
			left: 0;
			background: $lgray;
		}

		.decor-headline {
			position: absolute;
			top: -120px;
			left: 0;
			font-family: $sc_font;
			font-size: 200px;
			font-weight: 800;
			color: $altgray;

			@include max-down(1700px) {
				font-size: 160px;
				top: -100px;
			}

			@include max-down(1400px) {
				font-size: 120px;
				top: -80px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}

	.degree-programs-wrap {
		position: relative;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 250px;
			left: -410px;
			background: url('../svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			@include clearfix;
			width: 1205px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
			padding: 165px 0 120px 0;

			.info {
				@include section-line-before(60px, 20px);
				width: 650px;
				float: left;
				padding-left: 80px;

				.button-wrap {
					margin-top: 40px;

					.btn {
						min-width: 60%;
					}
				}
			}

			.programs {
				width: 385px;
				float: right;
				padding-top: 50px;

				h3 {
					margin-bottom: 20px;

					font-family: $c_font;
					font-size: 1rem;
					font-weight: 600;
					text-transform: uppercase;
					color: $gray;
					letter-spacing: 0.08em;
				}

				ul {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 100px;
					row-gap: 20px;
				}

				.arrow-btn {
					@include non-touch {
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}

			.mobile-button-wrap {
				display: none;
				margin-top: 50px;

				.btn {
					width: 100%;
				}
			}
		}

		@include max-down(1350px) {
			.inner-wrap {
				width: 90%;

				.info {
					width: 55%;
				}

				.programs {
					width: 35%;

					ul {
						column-gap: 5%;
					}
				}
			}
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}

			.inner-wrap {
				width: 90%;
				padding-top: 100px;

				.info {
					@include section-line-before-break;
					width: 100%;
					float: none;
					padding-left: 0;

					.button-wrap {
						display: none;
					}
				}

				.programs {
					width: 100%;
					float: none;
				}

				.mobile-button-wrap {
					display: block;
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				width: calc(100% - 40px);
				
				.programs ul {
					display: block;

					li {
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.cu-section__colors {
		.plus-pattern {
			left: auto;
			right: -75px;
			bottom: -130px;
			transform: rotate(-90deg);
		}

		@include max-down(1000px) {
			padding-bottom: 175px;
		}

		@include max-down(650px) {
			background: $blue;
			padding-bottom: 60px;

			.plus-pattern {
				display: none;
			}

			.inner-wrap {
				.inner-content-wrap {
					margin-top: -160px;

					.grid .grid-item.link {
						background: $white;

						.inner-content-wrap {
							h2 {
								color: $blue;
							}
						}
					}
				}
			}
		}
	}

	.page-background-wrap {
		position: relative;
		z-index: 2;
		padding-bottom: 260px;

		@include max-down(750px) {
			padding-bottom: 100px;
		}
	}

	.cu-section__half.two-images {
		position: relative;
		padding-top: 170px;

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 660px;
			position: absolute;
			top: -60px;
			left: 10px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 0;

			.diamond-pattern {
				display: none;
			}
		}
	}
}