.cu-section__featured-news {
	padding-bottom: 100px;
	background: $lgray;

	.cu-section__simple-content .inner-wrap {
		padding: 135px 0 70px;
	}

	.featured-news {
		width: 1475px;
		height: 710px;
		position: relative;
		margin: 0 auto;

		.pagination {
			display: block;
			width: calc(100% - 1025px);
			position: absolute;
			top: 50%;
			left: 0;
			z-index: 2;
			transform: translateY(-50%);

			.post-mobile-image {
				display: none;
			}

			.post {
				padding: 40px 20px 40px 35px;
				border-top: 2px solid $altgray;

				&:first-child {
					border-top: 0;
				}

				.button-wrap {
					height: 0;
					overflow: hidden;
				}

				&.active {
					width: calc(100% + 75px);
					background: $white;
					box-shadow: 6px 6px 24px rgba($black, 0.16);
					border-top: 0;

					h2 {
						font-size: 36px;
						line-height: 34px;
					}

					.button-wrap {
						height: auto;
						margin-top: 25px;
					}

					&~.post {
						border-top: 0;

						&~.post {
							border-top: 2px solid $altgray;
						}
					}
				}
			}
		}

		.image-wrap {
			width: 1025px;
			height: 710px;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			.image {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
	}

	@include max-down(1600px) {
		.featured-news {
			width: 90%;

			.pagination {
				width: 30%;
			}

			.image-wrap {
				width: 70%;
			}
		}
	}

	@include max-down(1160px) {
		.cu-section__simple-content .inner-wrap {
			padding-top: 100px;
		}
	}

	@include max-down(1000px) {
		.featured-news {
			height: auto;

			.pagination {
				display: block;
				width: 100%;
				position: static;
				background: transparent;
				transform: none;

				.post-mobile-image {
					display: block;
					height: 240px;
				}

				.post {
					border: 0 !important;
					box-shadow: none !important; 
					background: $white;
					margin-bottom: 25px;

					&:last-child {
						margin-bottom: 0;
					}

					&.active {
						width: 100%;

						h2 {
							font-size: 22px;
							line-height: 1.2em;
						}
					}

					.button-wrap {
						height: auto;
						margin-top: 25px;
					}
				}
			}

			.image-wrap {
				display: none;
			}
		}
	}
}