.cu-section__full-featured {
	width: 1770px;
	height: 740px;
	position: relative;
	z-index: 2;
	margin: 0 auto;

	&.tbx-animated {
		.inner-wrap .inner-content-wrap {
			&:before {
				width: 0;
				transition: width .7s;
			}

			h2,
			.copy,
			.button-wrap {
				opacity: 0;
			}
		}

		&.tbx-animated-siv-ft {
			.inner-wrap .inner-content-wrap {
				&:before {
					width: 60px;
				}

				h2 {
					@include fadeInUp($duration: 0.7s, $delay: 0.35s);
				}

				.copy {
					@include fadeInUp($duration: 0.7s, $delay: 0.7s);
				}

				.button-wrap {
					@include fadeInUp($duration: 0.7s, $delay: 0.95s);
				}
			}
		}

		@include max-down(1000px) {
			.inner-wrap .inner-content-wrap {
				&:before {
					width: 2px;
					transition: none;
				}
			}

			&.tbx-animated-siv-ft {
				.inner-wrap .inner-content-wrap {
					&:before {
						width: 2px;
					}

					h2 {
						@include fadeInUp($duration: 0.7s);
					}

					.copy {
						@include fadeInUp($duration: 0.7s, $delay: 0.35s);
					}

					.button-wrap {
						@include fadeInUp($duration: 0.7s, $delay: 0.7s);
					}
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap .inner-content-wrap {
				h2,
				.copy,
				.button-wrap {
					opacity: 1;
					animation: none !important;
				}
			}
		}
	}

	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: $black;
		background: linear-gradient(90deg, rgba($black,0.6) 25%, rgba($black,0) 45%); 
	}

	.inner-wrap {
		width: calc(100% - 140px);
		margin: 0 auto;

		.inner-content-wrap {
			@include section-line-before(60px, 20px);
			width: 600px;
			padding-left: 80px;

			.copy {
				width: 425px;
			}

			.button-wrap {
				margin-top: 25px;
			}
		}
	}

	@include max-down(1921px) {
		width: 95%;
	}

	@include max-down(1000px) {
		&:before {
			background: rgba($black, 0.6);
		}

		.inner-wrap {
			width: 80%;

			.inner-content-wrap {
				@include section-line-before-break;
				width: 100%;
				padding-left: 0;
				text-align: center;

				&:before {
					width: 2px;
					height: 60px;
					margin-left: auto;
					margin-right: auto;
				}

				.copy {
					width: 100%;
				}
			}
		}
	}

	@include max-down(600px) {
		width: calc(100% - 40px);

		&:before {
			background: rgba($black, 0.6);
		}
	}
}