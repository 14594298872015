.wpforms-container {

	&.wpforms-container-full {
		margin: 0;
	}

	.wpforms-confirmation-container-full {
		background: $dblue;
		color: $white;
		border: 0;
		margin-bottom: 0;
	}

	form.wpforms-form {
		.wpforms-page-indicator.none {
			display: none;
		}

		.wpforms-field-container {
			.wpforms-field {
				padding: 0;
				margin-bottom: 15px;
				overflow: visible !important;

				> label {
					margin-bottom: 7px;

					font-family: $c_font;
					font-size: 17px;
					color: $dblue;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;

					&.wpforms-error {
						margin-top: 5px;

						font-family: $n_font;
						font-size: 15px;
						font-weight: 700;
						text-transform: none;
						letter-spacing: 0;
						color: $gold !important;
					}
				}

				input[type="text"],
				input[type="number"],
				input[type="email"],
				input[type="tel"],
				select,
				textarea {
					appearance: none;
					width: 100%;
					height: 35px;
					border: 1px solid $dblue;
					border-radius: 0;
					outline: 0;
					background: none;
					padding: 0 12px;
					margin: 0;

					font-family: $n_font;
					font-size: 15px;
					text-transform: none;
					color: $black;
				}

				select {
					padding-right: 30px;
					background: url('../svg/select-arrow-gold.svg') right 7px center no-repeat;
					background-size: 16px 8px;

					option {
						color: $black;

						&[selected] {
							color: $black;
						}
					}
				}

				textarea {
					height: 240px;
				}

				&.wpforms-field-checkbox li,
				&.wpforms-field-radio li {
					input[type="checkbox"],
					input[type="radio"] {
						display: none;
					}

					label {
						display: block;
						position: relative;
						padding-left: 25px;

						font-family: $n_font;
						font-size: 17px;
						text-transform: none;
						color: $black;

						&:before {
							content: '';
							display: block;
							width: 16px;
							height: 16px;
							position: absolute;
							top: 5px;
							left: 0;
							border: 2px solid $blue;
						}
					}

					input[type="checkbox"]:checked + label:before,
					input[type="radio"]:checked + label:before {
						background: $blue;
					}
				}

				&.wpforms-field-radio li {
					label:before {
						border-radius: 50%;
					}
				}

				&.wpforms-field-checkbox.wpforms-list-inline li,
				&.wpforms-field-radio.wpforms-list-inline li {
					margin-bottom: 0 !important;
				}

				&.wpforms-field-pagebreak {
					margin: 0;
					padding: 0;

					.wpforms-pagebreak-left,
					.wpforms-pagebreak-right {
						text-align: center;
					}
				}
			}
		}

		button.wpforms-page-button,
		button.wpforms-submit {
			display: inline-block;
			padding: 10px 30px 12px;
			background: none !important;
			border: 2px solid $gold !important;
			border-radius: 0;

			font-size: 1rem;
			font-weight: 600;
			font-family: $c_font;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $dblue !important;

			@include non-touch {
				transition: border-color .3s, background .3s, color .3s;

				&:hover {
					border-color: $gold !important;
					background: $gold !important;
					color: $white !important;
				}
			}
		}

		.wpforms-submit-container {
			padding: 0 !important;
			margin-top: 20px;
			text-align: center;
		}
	}

	.form-white & {
		form.wpforms-form {
			.wpforms-field-container {
				.wpforms-field {
					label {
						color: $white;
					}

					input[type="text"],
					input[type="number"],
					input[type="email"],
					input[type="tel"],
					select,
					textarea {
						border-color: $white;
						color: $white;
					}

					&.wpforms-field-checkbox li,
					&.wpforms-field-radio li {
						label {
							color: $white;

							&:before {
								border-color: $white;
							}
						}

						input[type="checkbox"]:checked + label:before,
						input[type="radio"]:checked + label:before {
							background: $white;
						}
					}
				}
			}

			button.wpforms-page-button,
			button.wpforms-submit {
				color: $white !important;
			}
		}
	}
}