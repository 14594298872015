.cu-section__mobile-filter-bar {
	display: none;
	width: calc(100% - 40px);
	margin: 70px auto 40px auto;
	padding-bottom: 25px;
	border-bottom: 1px solid $gold;

	.filters {
		@include clearfix;

		.mobile-filter-overlay-toggle {
			display: block;
			width: 47.5%;
			float: left;
			padding: 0 55px 0 20px;
			border: 1px solid $blue;
			border-radius: 16px;
			background: $white url('../svg/filter-toggle.svg') right 15px center no-repeat;
			background-size: 19px;

			font-family: $c_font;
			font-size: 1rem;
			line-height: 32px;
			font-weight: 600;
			letter-spacing: 0.08em;
			color: $blue;
			text-transform: uppercase;
			text-align: left;
		}

		.search {
			display: block;
			width: 47.5%;
			position: relative;
			float: right;

			input[type="text"] {
				appearance: none;
				display: block;
				width: 100%;
				height: 34px;
				border: 0;
				border-radius: 16px;
				background: $lgray;
				padding: 0 55px 0 20px;

				font-family: $c_font;
				font-size: 1rem;
				line-height: 1em;
				font-weight: 600;
				letter-spacing: 0.08em;
				color: $blue;
				text-align: left;

				transition: width .3s;

				&:placeholder {
					opacity: 1;
					color: $blue;
				}

				&::placeholder {
					opacity: 1;
					color: $blue;
				}
			}

			button[type="submit"] {
				@include hidden-text;
				width: 15px;
				min-width: 0;
				height: 16px;
				position: absolute;
				top: 8px;
				right: 20px;
				border: 0;
				padding: 0;
				font-size: 0;
				background: url('../svg/search-icon-blue.svg') center no-repeat;
				background-size: contain;
			}
		}
	}

	.active-filters-wrap {
		margin-top: 15px;
		position: relative;
		z-index: 1;

		.active-filters-header {
			@include clearfix;

			.label {
				display: block;
				float: left;

				font-family: $c_font;
				font-size: 0.875rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;
			}

			.clear-mobile-filters {
				display: block;
				float: right;

				font-family: $c_font;
				font-size: 0.875rem;
				font-weight: 600;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				color: $blue;
				line-height: 1em;

				&:after {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 8px;
					height: 8px;
					margin-left: 10px;
					transform: translateY(-1px);
					background: url('../svg/close-blue.svg') center no-repeat;
					background-size: contain;
				}
			}
		}

		.active-filters {
			margin-top: 15px;

			button {
				display: inline-block;
				vertical-align: middle;
				padding: 10px;
				margin-right: 10px;
				background: $lgray;

				font-family: $c_font;
				font-size: 0.777rem;
				font-weight: 600;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				color: $blue;

				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 8px;
					height: 8px;
					margin-right: 10px;
					transform: translateY(-1px);
					background: url('../svg/close-blue.svg') center no-repeat;
					background-size: contain;
				}
			}
		}

		&.hidden {
			display: none;
		}
	}

	@include max-down(1000px) {
		display: block;
	}
}