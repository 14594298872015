.page-template-page-programs {
	.graduate-wrap {
		width: 1470px;
		position: relative;
		margin: 0 auto 200px;
		background: $blue;

		.image {
			width: 575px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
		}

		.info {
			width: calc(100% - 575px);
			padding: 60px 0;

			.inner-content-wrap {
				width: calc(100% - 200px);
				margin: 0 auto;
			}

			.inner {
				@include section-line-before(30px, 10px);
				width: 550px;
				margin: 0 0 0 auto;
				padding-left: 50px;
			}

			.programs {
				margin-top: 25px;

				ul {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 50px;
					row-gap: 10px;
				}
			}

			.button-wrap {
				margin-top: 35px;
			}
		}

		@include max-down(1600px) {
			width: 90%;
		}

		@include max-down(1480px) {
			.info {
				.inner {
					width: 100%;
				}
			}
		}

		@include max-down(1300px) {
			.image {
				width: 45%;
			}

			.info {
				width: 55%;

				.inner-content-wrap {
					width: calc(100% - 100px);
				}
			}
		}

		@include max-down(1000px) {
			margin-bottom: 120px;

			.image {
				width: 100%;
				height: 325px;
				position: static;
			}

			.info {
				width: 100%;
			}
		}

		@include max-down(600px) {
			margin-bottom: 75px;

			.info .inner {
				@include section-line-before-break;
				padding-left: 0;
			}

			.info .programs ul {
				grid-template-columns: 1fr;
			}
		}
	}
}