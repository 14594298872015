.cu-section__explore-nav-placeholder {
	&.active {
		height: 41px;
	}


	@include max-down(1000px) {
		&.active {
			height: 65px;
		}
	}
}

.cu-section__explore-nav {
	width: calc(100% - 40px);
	position: relative;
	z-index: 2;
	margin: 0 0 0 auto;
	padding: 12px 0;
	border-bottom: 1px solid $gold;
	background: $white;
	font-size: 0;
	text-align: center;

	.title {
		display: inline-block;
		vertical-align: middle;
		margin-right: 50px;

		font-family: $c_font;
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		color: $gray;
	}

	.explore-nav {
		display: inline-block;
		vertical-align: middle;

		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 50px;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.mobile-explore-nav {
		appearance: none;
		display: none;
		vertical-align: middle;
		width: 220px;
		position: relative;
		padding: 0;
		border: 0;
		outline: 0;
		border-radius: 0;
		overflow: hidden;
		white-space: nowrap;
		background: none;

		font-family: $c_font;
		font-size: 0.875rem;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 0.08em;
		color: $blue;
		text-align: right;
		text-overflow: ellipsis;
	}

	&.fixed {
		width: 100%;
		max-width: 1920px;
		position: fixed;
		top: 70px;
		right: calc((100% - 1920px)/2);
		z-index: 10;

		@include max-down(1160px) {
			top: 107px;
			}
		@include max-down(1921px) {
			right: 0;
		}
	}

	&.has-back-button {
		.title {
			display: none;
		}

		.back-wrap {
			position: absolute;
			top: 50%;
			left: 25px;
			transform: translateY(-50%);
		}

		@include max-down(1400px) {
			padding-left: 250px;
		}

		@include max-down(1000px) {
			padding-left: 0;

			&.fixed {
				padding-left: 20px;
			}

			.title {
				display: block;
			}

			.back-wrap {
				display: none;
			}
		}
	}

	@include max-down(1921px) {
		&.fixed {
			width: 100%;
		}
	}

	@include max-down(1200px) {
		.title {
			margin-right: 25px;
		}

		.explore-nav li {
			margin-right: 25px;
		}
	}

	@include max-down(1000px) {
		@include clearfix;
		padding-right: 20px;

		.title {
			display: block;
			float: left;
			width: calc(100% - 220px);
			margin-right: 0;
			line-height: 25px;
			text-align: left;
		}

		.explore-nav {
			display: none;
		}

		.mobile-explore-nav {
			display: block;
			float: right;
		}
	}

	@include max-down(750px) {
		width: calc(100% - 20px);
	}

	@include max-down(600px) {
		.title {
			width: calc(100% - 150px);
		}

		.mobile-explore-nav {
			width: 150px;
		}
	}
}