.cu-card__plo {
	position: relative;
	height: 475px;
	background: $lgray;
	overflow: hidden;

	a {
		display: block;
		width: 100%;
		height: 100%;
		text-decoration: none;
	}

	.image {
		width: 100%;
		height: 230px;
		position: relative;
		z-index: 1;
	}

	.info {
		width: 100%;
		height: calc(100% - 230px);
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		min-height: 150px;
		padding: 30px 25px;
		background: $lgray;

		transition: height .3s;

		.headline.small {
			line-height: 1em;
		}

		.inner-content-wrap {
			height: 0;
			opacity: 0;
			margin-top: 15px;

			transition: opacity .3s;
		}

		.button-wrap {
			position: absolute;
			bottom: 30px;
			left: 25px;
		}
	}

	@include non-touch {
		&:hover {
			.info {
				height: 100%;

				.inner-content-wrap {
					height: auto;
					opacity: 1;

					transition: opacity .15s .3s;
				}
			}
		}
	}

	@include max-down(1160px) {
		box-shadow: 0 10px 15px -6px rgba($black, 0.5);
		transition: box-shadow .3s;

		&:active {
			box-shadow: none;
		}
	}

	@include max-down(600px) {
		height: auto;

		.info {
			position: static;
			padding: 25px 30px;

			.inner-content-wrap {
				.copy {
					display: none;
				}
			}

			.button-wrap {
				position: static;
				margin-top: 25px;
			}
		}

		&:hover {
			.info {
				height: 60%;
			}
		}
	}
}