@mixin phone {
	@media only screen and (max-width: $phone_breakpoint) { @content; }
}

@mixin tablet {
	@media only screen and (max-width: $tablet_breakpoint) { @content; }
}

@mixin desktop {
	@media only screen and (max-width: $desktop_breakpoint) { @content; }
}

@mixin max-down($max) {
	@media only screen and (max-width: $max - 1) { @content; }
}

@mixin non-touch {
 	@media only screen and (hover: hover) { @content; }
}

@mixin clearfix {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin hidden-text {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-indent: 200%;
}

@mixin transition($time, $property: all, $easing: ease-in) {
    transition: $property $time $easing;
}

@mixin image-overlay($opacity: 0.3) {
	position: relative;
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, $opacity);
		z-index: 1;
	}
	> div {
		position: relative;
		z-index: 2;
	}
}

@mixin cutout-triangle-left {
	clip-path: polygon(100% 0, 0 0, 0 calc(50% - 20px), 20px 50%, 0 calc(50% + 20px), 0 100%, 100% 100%);
}

@mixin cutout-triangle-right {
	clip-path: polygon(0 0, 100% 0, 100% calc(50% - 20px), 95% 50%, 100% calc(50% + 20px), 100% 100%, 0 100%);
}

@keyframes pulse {
	0% {
		opacity: 0.3;
	}
	100% {
		opacity: 0.7;
	}
}

@mixin section-line-before($width: 60px, $top: 10px) {
	position: relative;

	&:before {
		content: '';
		display: block;
		width: $width;
		height: 2px;
		position: absolute;
		top: $top;
		left: 0;
		background: $gold;
	}
}

@mixin section-line-before-break {
	&:before {
		position: static;
		margin-bottom: 15px;
	}
}

@mixin vertical-line-before {
	position: relative;
	padding-top: 140px;

	&:before {
		content: '';
		display: block;
		width: 2px;
		height: 60px;
		position: absolute;
		top: 45px;
		left: calc(50% - 1px);
		background: $gold;
	}
}
