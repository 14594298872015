.cu-section__number-list {
	padding: 145px 0;

	.inner-wrap {
		width: 760px;
		margin: 0 auto;

		.step {
			min-height: 80px;
			position: relative;
			padding-left: 60px;
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}

			.number {
				display: block;
				position: absolute;
				top: 0;
				left: 0;

				font-family: $ec_font;
				font-size: 72px;
				font-weight: 300;
				line-height: 58px;
				color: $blue;
			}
			
			.inner-content-wrap {
				@include section-line-before(30px, 35px);
				padding-left: 50px;
				padding-top: 20px;
			}
		}
	}

	&.white {
		.inner-wrap .step .number {
			color: $white;
		}
	}

	@include max-down(900px) {
		.inner-wrap {
			width: 90%;
		}
	}

	@include max-down(600px) {
		padding: 65px 0;
	}
}