.page-template-page-general-accreditation {
	.background-wrap {
		position: relative;
		padding-top: 140px;

		@include max-down(600px) {
			padding-top: 100px;
		}

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 345px);
			position: absolute;
			top: 0;
			left: 0;
			background: $lgray;

			@include max-down(750px) {
				height: calc(100% - 250px);
			}
		}

		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			@include max-down(1300px) {
				width: 90%;
			}

			@include max-down(600px) {
				width: calc(100% - 40px);
			}
		}

		.testimonial-wrap {
			position: relative;
			z-index: 2;
			margin-top: 145px;

			.triangle-pattern {
				display: block;
				width: 383px;
				height: 727px;
				position: absolute;
				top: -105px;
				right: 0;
				background: url('../svg/triangle-pattern-gold-spread.svg') center no-repeat;
				background-size: contain;
			}

			@include max-down(750px) {
				.triangle-pattern {
					display: none;
				}
			}

			@include max-down(600px) {
				margin-top: 100px;
			}
		}
	}

	.recognized-wrap {
		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 135px;
				padding-bottom: 0;
			}
		}

		.recognized-by-list {
			margin-top: 125px;

			ul {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				width: 900px;
				margin: 0 auto;

				li {
					align-items: center;
					justify-self: center;

					a {
						display: block;
						width: 100%;
						height: 100%;
						text-decoration: none;
					}

					img {
						display: inline-block;
						max-width: 90%;
						max-height: 160px;
					}
				}
			}
		}

		@include max-down(1000px) {
			.cu-section__simple-content .inner-wrap {
				padding-top: 100px;
			}

			.recognized-by-list {
				margin-top: 75px;

				ul {
					width: 95%;
				}
			}
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-top: 250px;
		padding-bottom: 175px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -95px;
			left: -210px;
			background: url('../svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 150px;
		}

		@include max-down(600px) {
			padding-top: 100px;
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}
	}
}