.home,
.page-template-page-home {
	.hero-notice {
		display: flex;
		align-items: stretch;
		width: 1100px;
		position: relative;
		z-index: 2;
		margin: -85px auto 0;
		background: $lgray;

		.image {
			width: 240px;
		}

		.info {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			width: calc(100% - 240px);
			padding: 35px 50px;

			.button-wrap {
				margin-top: 25px;
			}
		}

		@include max-down(1250px) {
			width: 85%;
		}

		@include max-down(1000px) {
			width: calc(100% - 80px);
			margin-top: 45px;
		}

		@include max-down(750px) {
			width: calc(100% - 40px);

			.image {
				width: 150px;
			}

			.info {
				width: calc(100% - 150px);
			}
		}

		@include max-down(700px) {
			margin-top: 15px;
		}

		@include max-down(500px) {
			.info {
				padding: 35px;
			}
		}

		@include max-down(450px) {
			.image {
				display: none;
			}

			.info {
				width: 100%;
			}
		}
	}

	.main-wrap {
		@include clearfix;
		position: relative;
		z-index: 1;
		padding-bottom: 240px;
		margin-top: 75px;

		&.tbx-animated {
			.images .image-1,
			.images .image-2,
			.info h2,
			.info .copy,
			.info .button-wrap {
				opacity: 0;

				@include max-down(700px) {
					opacity: 1;
					animation: none !important;
				}
			}

			&.tbx-animated-siv-ft {
				.images .image-1 {
					@include fadeInLeft($duration: 0.7s);
				}

				.images .image-2 {
					@include fadeInUp($duration: 0.7s, $delay: 0.35s);
				}

				.image-3 {
					@include fadeIn($duration: 0.7s, $delay: 0.7s);
				}

				.info .inner-content-wrap:before {
					width: 60px;
				}

				.info h2 {
					@include fadeIn($duration: 0.7s, $delay: 1.05s);
				}

				.info .copy {
					@include fadeIn($duration: 0.7s, $delay: 1.4s);
				}

				.info .button-wrap {
					@include fadeInUp($duration: 0.7s, $delay: 1.75s);
				}
			}
		}

		.plus-pattern {
			content: '';
			display: block;
			width: 494px;
			height: 537px;
			position: absolute;
			top: 255px;
			left: -80px;
			z-index: 1;
			background: url('../svg/plus-pattern-small-gold.svg') center no-repeat;
			background-size: contain;
		}

		.images {
			width: 910px;
			height: 640px;
			float: left;
			position: relative;
			z-index: 2;

			.image-1 {
				width: 575px;
				height: 405px;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
			}

			.image-2 {
				width: 575px;
				height: 535px;
				position: absolute;
				top: 90px;
				right: 0;
				z-index: 2;

				.tbx-module__video-overlay-toggle {
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;

					.icon {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.info {
			width: calc(100% - 910px);
			float: right;
			position: relative;
			z-index: 2;
			padding-top: 105px;

			.inner-content-wrap {
				width: 580px;
				position: relative;
				margin-left: 90px;
				margin-right: auto;
				padding-left: 80px;

				&:before {
					content: '';
					display: block;
					width: 0;
					height: 2px;
					position: absolute;
					top: 27px;
					left: 0;
					background: $gold;
					transition: width .35s .7s;
				}

				.button-wrap {
					margin-top: 40px;
					font-size: 0;

					span {
						display: inline-block;
						vertical-align: middle;
						margin-right: 20px;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}

		@include max-down(1700px) {
			.images {
				.image-2 {
					right: 100px;
				}
			}

			.info .inner-content-wrap {
				width: calc(100% - 50px);
				margin: 0 auto 0 0;
			}
		}

		@include max-down(1600px) {
			.images {
				width: 600px;
				height: 445px;

				.image-1 {
					width: 350px;
					height: 298px;
				}

				.image-2 {
					width: 375px;
					height: 349px;
					right: 0;
				}
			}

			.info {
				width: calc(100% - 650px);
			}
		}

		@include max-down(1400px) {
			.info {
				.inner-content-wrap {
					padding-left: 0;

					&:before {
						position: static;
						margin-bottom: 20px;
					}
				}
			}
		}

		@include max-down(1300px) {
			.info {
				padding-top: 120px;

				.inner-content-wrap .inner {
					.left {
						width: 100%;
						float: none;
					}

					.right {
						display: none;
					}
				}
			}
		}

		@include max-down(1000px) {
			padding-top: 0;
			padding-bottom: 240px;

			.plus-pattern {
				display: none;
			}

			.images {
				float: none;
				margin: 0 auto;
			}

			.info {
				width: 100%;
				float: none;
				padding-top: 55px;

				.inner-content-wrap {
					width: 80%;
					margin: 0 auto;
				}
			}
		}

		@include max-down(700px) {
			margin-top: 15px;
			padding-bottom: 200px;

			.images {
				width: calc(100% - 40px);
				height: auto;
				position: static;

				.image-1 {
					display: none;
				}

				.image-2 {
					width: 100%;
					height: auto;
					position: relative;
					top: auto; right: auto; bottom: auto; left: auto;
				}
			}

			.info {
				padding-top: 55px;
			}
		}

		@include max-down(600px) {
			margin-top: 35px;
			background-position: bottom left -300px !important;

			.images {
				width: calc(100% - 40px);
			}

			.info {
				.inner-content-wrap {
					width: calc(100% - 40px);

					&:before {
						width: 40px;
					}

					.inner .left .button-wrap span {
						display: block;
						margin-right: 0;
						margin-bottom: 15px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.values-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 410px;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: $blue;
		}

		.diamond-pattern {
			display: block;
			width: 345px;
			height: 490px;
			position: absolute;
			top: 190px;
			right: 0;
			z-index: 2;
			background: url('../svg/diamond-pattern-4-column.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1470px;
			position: relative;
			z-index: 3;
			margin: 0 auto;

			.image {
				width: 1170px;
				height: 740px;
				position: relative;
				z-index: 2;
				margin: 0 0 0 auto;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background: $blue;
					background: linear-gradient(45deg, $blue 25%, $brown 75%); 
					opacity: 0.45;
				}
			}

			.info {
				width: 725px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(calc(-50% - 30px));
				z-index: 3;
				padding: 85px 0;
				background: $white;
				box-shadow: 6px 6px 24px rgba($black, 0.16);

				.inner-content-wrap {
					width: 580px;
					position: relative;
					margin: 0 auto;
					padding-left: 80px;

					&:before {
						content: '';
						display: block;
						width: 60px;
						height: 2px;
						position: absolute;
						top: 27px;
						left: 0;
						background: $gold;
					}

					.button-wrap {
						margin-top: 40px;
					}
				}
			}
		}

		@include max-down(1600px) {
			.inner-wrap {
				width: 90%;

				.image {
					width: 85%;
				}

				.info {
					width: 45%;

					.inner-content-wrap {
						width: 80%;
						padding-left: 0;

						&:before {
							position: static;
							margin-bottom: 15px;
						}
					}
				}
			}
		}

		@include max-down(1260px) {
			.inner-wrap {
				.info {
					width: 55%;
				}
			}
		}

		@include max-down(1000px) {
			&:before,
			.diamond-pattern {
				display: none;
			}

			.inner-wrap {
				width: 100%;

				.image {
					width: 100%;
					height: auto;
					margin: 0;
				}

				.info {
					width: 100%;
					position: static;
					transform: none;
				}
			}
		}
	}
}