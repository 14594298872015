.cu-part__search-overlay-top {
	width: 1920px;
	height: 50vh;
	position: fixed;
	top: -50vh;
	right: calc((100% - 1920px)/2);
	z-index: 100;
	background: $blue;
	text-align: center;
	transition: top 0.6s cubic-bezier(0.2,1,0.3,1);
	overflow: hidden;

	.close-search-overlay {
		@include hidden-text;
		display: block;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 25px;
		right: 25px;
		background: url('../svg/close.svg') center no-repeat;
		background-size: contain;
	}

	.search-form {
		display: inline-block;
		width: 750px;
		position: relative;
		font-size: 0;

		input[type="text"] {
			appearance: none;
			display: block;
			width: 585px;
			height: 42px;
			border: 0;
			border-bottom: 1px solid $lgray;
			outline: 0;
			border-radius: 0;
			background: url('../svg/search-icon-gold.svg') left 20px center no-repeat;
			background-size: 23px 24px;
			padding: 0;
			margin: 0;
			margin-right: 15px;
			padding-left: 65px;

			font-family: $ec_font;
			font-size: 28px;
			font-weight: 600;
			letter-spacing: 0.18em;
			color: $white;
		}

		button[type="submit"] {
			width: 140px;
			position: absolute;
			top: 2px;
			right: 0;
		}

		.buckets {
			margin-top: 30px;

			h2,
			label {
				display: inline-block;
				vertical-align: middle;
				margin: 0;

				font-size: 1rem;
				font-family: $c_font;
				text-transform: uppercase;
				color: $white;
			}

			h2 {
				margin-right: 30px;
			}

			.inputs,
			.input {
				display: inline-block;
				vertical-align: middle;
			}

			.input {
				margin-right: 30px;
			}

			input[type="radio"] {
				display: none;
			}

			.input label:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 14px;
				height: 14px;
				margin-top: -3px;
				margin-right: 10px;
				border: 2px solid $gold;
				border-radius: 100%;
			}

			input[type="radio"]:checked + label:before {
				background: $gold;
			}
		}
	}

	&.show {
		top: 0;
	}

	@include max-down(1921px) {
		width: 100%;
		right: 0;
	}

	@include max-down(1000px) {
		height: 30vh;
		top: -30vh;
	}

	@include max-down(900px) {
		.search-form {
			width: 90%;
			margin: 0 auto;
			text-align: left;

			input[type="text"] {
				width: calc(100% - 165px);
			}

			.buckets {
				h2 {
					display: block;
					margin-right: 0;
					margin-bottom: 15px;
				}

				.inputs {
					display: block;
				}

				.input {
					display: block;
					margin-right: 0;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	@media screen and (max-width: 750px) and (orientation: portrait) {
		height: 100vh;
		top: -100vh;
	}

	@media screen and (max-height: 450px) and (orientation: landscape) {
		height: 100vh;
		top: -100vh;
	}

	@include max-down(600px) {
		.close-search-overlay {
			width: 20px;
			height: 20px;
			top: 15px;
			right: 15px;
		}
	}
}

.cu-part__search-overlay-bottom {
	width: 1920px;
	height: 50vh;
	position: fixed;
	top: 100vh;
	right: calc((100% - 1920px)/2);
	z-index: 100;
	transition: top 0.6s cubic-bezier(0.2,1,0.3,1);
	overflow: auto;

	.inner-content-wrap {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 50px;
		width: 1160px;
		margin: 0 auto;
		padding: 65px 0;

		li {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.show {
		top: 50vh;
	}

	@include max-down(1921px) {
		width: 100%;
		right: 0;
	}

	@include max-down(1300px) {
		.inner-content-wrap {
			width: 90%;
		}
	}

	@include max-down(1000px) {
		height: 70vh;

		.inner-content-wrap {
			grid-template-columns: 1fr;
			row-gap: 35px;
		}

		&.show {
			top: 30vh;
		}
	}

	@media screen and (max-width: 750px) and (orientation: portrait) {
		display: none;
	}

	@media screen and (max-height: 450px) and (orientation: landscape) {
		display: none;
	}
}