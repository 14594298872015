.page-template-page-landing-page-content-offer {
	@include max-down(1000px) {
		padding-top: 58px;
	}

	@include max-down(600px) {
		padding-top: 54px;
	}

	.cu-section__hero {
		padding-top: 121px;
		
		.inner-wrap {
			margin: 0 auto;
		}
	}

	.image-video-wrap {
		width: 1470px;
		height: 710px;
		position: relative;
		z-index: 2;
		margin: 0 auto;

		.image {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 1;
		}

		button {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
		}

		@include max-down(1600px) {
			width: 90%;
		}

		@include max-down(1300px) {
			height: 650px;
		}

		@include max-down(1000px) {
			height: 600px;
		}

		@include max-down(600px) {
			width: calc(100% - 40px);
			height: 550px;
		}
	}

	.about-wrap {
		background: $blue;
		margin-top: -175px;
		padding: 255px 0 135px;

		.inner-wrap {
			@include clearfix;
			width: 1320px;
			margin: 0 auto;
		}

		.inner-content-wrap {
			width: 655px;
			float: left; 
			position: relative;
			padding-left: 80px;
			padding-top: 40px;

			h2:before {
				width: 60px;
				position: absolute;
				top: 60px;
				left: 0;
			}

			.button-wrap {
				margin-top: 45px;
			}
		}

		.author {
			width: 425px;
			float: right;
			padding: 40px 35px;
			background: $white;

			.image {
				width: 200px;
				height: 200px;
				margin: 0 auto 30px;
				border-radius: 100%;
				overflow: hidden;
			}

			h3 {
				text-align: center;
			}
		}

		@include max-down(1500px) {
			.inner-wrap {
				width: 90%;
			}

			.inner-content-wrap {
				width: calc(100% - 500px);
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.inner-content-wrap {
				width: 100%;
				float: none;
				padding-top: 0;
				padding-left: 0;

				h2:before {
					width: 40px;
					position: static;
				}
			}

			.author {
				width: 100%;
				float: none;
				margin-top: 100px;
			}
		}

		@include max-down(600px) {
			padding-bottom: 60px;

			.inner-wrap {
				width: calc(100% - 40px);
			}
		}
	}

	.expanders-wrap {
		padding-bottom: 115px;

		.cu-section__simple-content {
			.inner-wrap {
				padding-top: 140px;
			}
		}

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.cu-section__simple-content {
				.inner-wrap {
					padding-top: 100px;
				}
			}
		}

		@include max-down(600px) {
			padding-bottom: 60px;

			.cu-section__simple-content {
				.inner-wrap {
					padding-top: 60px;
				}
			}

   			.cu-part__expanders {
   				width: calc(100% - 40px);
   			}
		}
	}

	.form-wrap {
		margin-bottom: 140px;

		.form {
			width: 600px;
			margin: 0 auto;
		}

		@include max-down(1000px) {
			margin-bottom: 100px;
		}

		@include max-down(750px) {
			.form {
				width: 90%;
			}
		}

		@include max-down(600px) {
			margin-bottom: 60px;

			.form {
				width: calc(100% - 40px);
			}
		}
	}

	.page-background.last {
		padding-bottom: 140px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 60px;
		}
	}
}