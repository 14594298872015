.mobile-menu-shown {
	.landing-page-scrolled-header-wrap {
		box-shadow: none !important;
		background: none !important;
	}
}

.mobile-menu:not(.main-website) {
	//display: none;
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 15;
	background: $white;
	overflow: auto;

	.inner-wrap {
		padding: 140px 0;
	}

	nav {
		text-align: center;

		li {
			&:after {
				content: '';
				display: block;
				width: 40px;
				height: 2px;
				background: $gold;
				margin: 25px auto;
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			a {
				font-family: $sc_font;
				font-size: 1.33rem;
				font-weight: 700;
				letter-spacing: 0.02em;
				color: $blue;
				text-decoration: none;

				@include non-touch {
					transition: color .3s;

					&:hover {
						color: $gold;
					}
				}
			}
		}
	}

	.mobile-button-wrap {
		display: none;
		width: 90%;
		margin: 60px auto 0 auto;

		span {
			display: block;
			width: 100%;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			.btn {
				min-width: 0;
				width: 100%;
				padding-top: 20px;
				padding-bottom: 20px;

				font-family: $sc_font;
				font-size: 1.125rem;
				font-weight: 700;
			}
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			padding: 100px 0;
		}
		
		.mobile-button-wrap {
			display: block;
		}
	}
}