.cu-section__form {
	.inner-wrap {
		width: 640px;
		margin: 0 auto;
		padding: 120px 0 280px 0;

		iframe {
			width: 1px;
    		min-width: 100%;
		}

		@include max-down(750px) {
			width: 90%;
			padding: 120px 0;
		}

		@include max-down(600px) {
			padding: 100px 0;
		}

		@include max-down(480px) {
			padding: 75px 0;
		}
	}
}