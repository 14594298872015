.page-template-page-news-events {
	#events {
		position: relative;

		.triangle-pattern {
			display: block;
			width: 621px;
			height: 692px;
			position: absolute;
			top: 280px;
			right: 0;
			z-index: 1;
			background: url('../svg/triangle-pattern-gray-spread.svg') center no-repeat;
			background-size: contain;
		}

		> .info {
			position: relative;
			z-index: 2;
		}

		.event-list {
			position: relative;
			z-index: 2;
		}


		@include max-down(1000px) {
			.triangle-pattern {
				display: none;
			}
		}
	}

	#blogs {
		position: relative;
		padding-bottom: 180px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(750px) {
			padding-bottom: 75px;
		}

		.triangle-pattern {
			display: block;
			width: 624px;
			height: 893px;
			position: absolute;
			top: 50%;
			right: -125px;
			transform: translateY(-50%);
			z-index: 1;
			background: url('../svg/triangle-pattern-gold-spread-tall.svg') center no-repeat;
			background-size: cover;

			@include max-down(1000px) {
				display: none;
			}
		}

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -30px;
			left: -205px;
			z-index: 1;
			background: url('../svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;

			@include max-down(1000px) {
				display: none;
			}
		}

		.blog-list {
			width: 1210px;
			margin: 0 auto;

			@include max-down(1350px) {
				width: 90%;
			}
		}

		.blog {
			width: 100%;
			height: 435px;
			position: relative;
			z-index: 2;
			margin: 0 auto 100px auto;
			box-shadow: 6px 6px 24px rgba($black, 0.16);
			background: $white;

			&:last-child {
				margin-bottom: 0;
			}

			.image {
				width: 470px;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.info {
				width: calc(100% - 470px);
				height: 100%;
				margin: 0 0 0 auto;

				.inner-content-wrap {
					@include section-line-before(60px, 20px);
					width: calc(100% - 140px);
					margin: 0 auto;
					padding-left: 80px;

					.button-wrap {
						margin-top: 35px;
					}
				}
			}

			@include max-down(1350px) {
				.info .inner-content-wrap {
					@include section-line-before-break;
					padding-left: 0;
				}
			}

			@include max-down(1160px) {
				.image {
					width: 325px;
				}

				.info {
					width: calc(100% - 325px);

					.inner-content-wrap {
						width: calc(100% - 70px);
					}
				}
			}

			@include max-down(1000px) {
				height: auto;

				.image {
					width: 100%;
					height: 300px;
					position: static;
				}

				.info {
					width: 100%;
					height: auto;

					.inner-content-wrap {
						padding: 55px 0;
					}
				}
			}

			@include max-down(750px) {
				margin-bottom: 75px;
			}
		}
	}
}

.filter-list-more button {
	color: #081629;
}
.filter-list-more button:hover {
	color: #fff;
	background: #c93;
}