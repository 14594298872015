.page-template-page-general-template-4 {
	.page-background-wrap {
		padding-bottom: 280px;

		@include max-down(1160px) {
			padding-bottom: 200px;
		}

		@include max-down(750px) {
			padding-bottom: 120px;
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}

	.expanders-wrap {
		padding-bottom: 200px;
		
		.cu-part__expanders {
			width: 1170px;
			position: relative;
			z-index: 2;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(1160px) {
			padding-bottom: 120px;
		}

		@include max-down(600px) {
			padding-bottom: 75px;

			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__testimonial {
		position: relative;
		z-index: 3;
		margin-bottom: 200px;

		@include max-down(1160px) {
			margin-bottom: 120px;
		}

		@include max-down(600px) {
			margin-bottom: 75px;
		}
	}
}