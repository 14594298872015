@include keyframes(bounceOutLeft) {
    0% {
      @include transform(translateX(0));
    }
   20% {
     opacity: 1;
     @include transform(translateX(20px));
    }
  100% {
    opacity: 0;
    @include transform(translateX(-2000px));
  }
}

@mixin bounceOutLeft($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(bounceOutLeft);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
