.cu-card__faculty {
	background: $lgray;
	overflow: hidden;

	a {
		display: block;
		width: 100%;
		height: 100%;
		text-decoration: none;
	}

	.image {
		width: 100%;
		height: 230px;
	}

	.info {
		width: 100%;
		height: calc(100% - 230px);
		padding: 30px 25px;

		.title {
			margin-top: 15px;

			font-family: $sc_font;
			font-size: 0.875rem;
			font-weight: 700;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			color: $blue;
		}

		.button-wrap {
			margin-top: 20px;
		}
	}

	&:active {
		.info  .button-wrap .btn {
			background: $gold;
		}
	}
}