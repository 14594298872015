.page-template-page-graduate-landing {
	.full-featured-wrap {
		position: relative;

		.triangle-pattern {
			content: '';
			display: block;
			width: 300px;
			height: 745px;
			position: absolute;
			bottom: -255px;
			right: -25px;
			background: url('../svg/triangle-pattern-gray-tall.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(600px) {
			.triangle-pattern {
				display: none;
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 230px;

		@include max-down(650px) {
			padding-bottom: 100px;
		}
	}

	.cu-section__half.two-images {
		position: relative;
		padding-top: 170px;
		padding-bottom: 230px;

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 660px;
			position: absolute;
			top: -230px;
			left: 10px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(650px) {
			padding-top: 100px;
			padding-bottom: 0;
		}

		@include max-down(600px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__colors {
		margin-top: -230px;
		padding-bottom: 100px;

		.triangle-pattern {
			display: none;
		}

		.diamond-pattern {
			bottom: 0;
			right: 0;
			left: auto;
		}

		@include max-down(650px) {
			margin-top: 0;
		}
	}

	.cu-section__feature-blocks {
		padding-top: 0;
	}

	.cu-section__news-feed {
		padding: 120px 0 0 0;

		.plus-pattern {
			bottom: -140px;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-top: 190px;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: -40px;
			left: -205px;
			background: url('../svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-top: 100px;
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-top: 75px;
			padding-bottom: 75px;
		}
	}
}