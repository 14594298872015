.page-template-page-landing-page-2-column {
	@include max-down(1000px) {
		padding-top: 156px !important;
	}

	@include max-down(600px) {
		padding-top: 158px !important;
	}

	.content-wrap {
		position: relative;
		z-index: 1;
		background: $lgray;
		padding-right: 40vw;

		@include max-down(1000px) {
			padding-right: 0;
		}
	}

	.hero-wrap {
		padding: 220px 0;

		.inner-wrap {
			width: 80%;
			margin: 0 auto;
			padding-left: 80px;

			h1 {
				position: relative;

				&:before {
					width: 60px;
					position: absolute;
					left: -80px;
					top: 23px;
				}
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;
		}

		@include max-down(600px) {
			padding: 70px 0 90px;

			.inner-wrap {
				width: calc(100% - 40px);
				padding-left: 45px;

				h1:before {
					width: 25px;
					left: -45px;
					top: 15px;
				}
			}
		}
	}

	.form-wrap {
		display: flex;
		align-items: center;
		width: 40%;
		position: fixed;
		top: calc(50% + 65px);
		right: calc((100% - 1920px)/2);
		transform: translateY(-50%);

		> * {
			width: 70%;
			margin: 0 auto;
		}

		.fsBody .fsForm {
			margin: 0;
			padding: 0;
		}

		iframe {
			display: block;
			width: 100%;
		}

		@include max-down(1921px) {
			right: 0;
		}

		@include max-down(1000px) {
			width: 100%;
			position: static;
			transform: none;
			padding-bottom: 100px;
			padding-left: 80px;

			> * {
				width: 80%;
			}
		}

		@include max-down(600px) {
			padding-left: 0;
			padding-bottom: 65px;

			> * {
				width: calc(100% - 40px);
			}
		}
	}

	.cs1,
	.cs3 {
		position: relative;
		background: $white;
		padding: 135px 0 155px;

		&:before {
			content: '';
			display: block;
			width: 469px;
			height: 621px;
			position: absolute;
			top: 22px;
			left: -85px;
			z-index: 1;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 90%;
			position: relative;
			z-index: 2;
			margin: 0 auto;
		}

		.image {
			position: relative;
			height: 535px;

			.picture {
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 1;
			}

			.tbx-module__video-overlay-toggle {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 2;
			}
		}

		.info {
			padding-top: 95px;

			.inner-content-wrap {
				width: 90%;
				margin: 0 auto;
				padding-left: 80px;

				h2 {
					position: relative;

					&:before {
						width: 60px;
						position: absolute;
						left: -80px;
						top: 23px;
					}
				}

				.button-wrap {
					margin-top: 45px;
				}
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;
		}

		@include max-down(600px) {
			&:before {
				width: 147px;
				height: 402px;
				top: 20px;
				left: -15px;
				background-size: 469px 621px;
			}

			.inner-wrap {
				width: calc(100% - 40px);
			}

			.image {
				height: 380px;
			}

			.info {
				padding-top: 50px;

				.inner-content-wrap {
					width: 100%;
					padding-left: 45px;

					h2:before {
						width: 25px;
						left: -45px;
						top: 15px;
					}
				}
			}
		}
	}

	.cs2 {
		background: $blue;
		padding: 215px 0;

		.inner-wrap {
			width: 80%;
			margin: 0 auto;
			padding-left: 80px;

			h2 {
				position: relative;

				&:before {
					width: 60px;
					position: absolute;
					left: -80px;
					top: 23px;
				}
			}

			.button-wrap {
				margin-top: 45px;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;
		}

		@include max-down(600px) {
			padding: 65px 0;

			.inner-wrap {
				width: calc(100% - 40px);
				padding-left: 45px;

				h2:before {
					width: 25px;
					left: -45px;
					top: 15px;
				}
			}
		}
	}

	.cs3 {
		&:before {
			display: none;
		}
	}

	.background-wrap {
		background: $white;
		padding-bottom: 180px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 65px;
		}
	}

	.cu-section__testimonial {
		width: 90%;

		.inner-wrap {
			width: calc(100% - 300px);
		}

		@include max-down(1500px) {
			height: auto;

			.inner-wrap {
				padding: 100px 0;

				blockquote {
					width: 100%;
				}
			}
		}

		@include max-down(1400px) {
			.inner-wrap {
				width: 80%;
				margin: 0 auto;
			}
		}

		@include max-down(600px) {
			width: calc(100% - 40px);

			.inner-wrap {
				padding: 65px 0;
			}
		}
	}

	.expanders-wrap {
		padding: 140px 0 0;

		.inner-wrap {
			width: 80%;
			margin: 0 auto;
			padding-left: 80px;

			h2 {
				position: relative;

				&:before {
					width: 60px;
					position: absolute;
					left: -80px;
					top: 23px;
				}
			}

			.button-wrap {
				margin-top: 45px;
			}
		}

		.cu-part__expanders {
			width: 80%;
			margin: 85px auto 0;
			padding-left: 80px;

			.expander .expander-body .copy {
				width: 80%;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0 0;
		}

		@include max-down(600px) {
			padding: 65px 0 0;

			.inner-wrap {
				width: calc(100% - 40px);
				padding-left: 45px;

				h2:before {
					width: 25px;
					left: -45px;
					top: 15px;
				}
			}

			.cu-part__expanders {
				width: calc(100% - 40px);
				padding-left: 0;
			}
		}
	}

	.landing-page-footer-wrap {
		position: relative;
		z-index: 2;
	}
}