.page-template-page-components, .program-template-page-components { 
	.cu-section__news-feed.programlist {padding-top:65px;}
	.cu-section__news-feed.programlist .news-feed {margin-top:10px;
		.swiper-button-next {background: #ffffff66 url('../svg/slider-arrow-next-blue.svg') center no-repeat;}
		.swiper-button-prev {background: #ffffff66 url('../svg/slider-arrow-prev-blue.svg') center no-repeat;}

	}
	.postlist.programlist.postrotator {
		background-color:$white;
		margin-top:0px;
		padding-top:15px;

		.cu-section__simple-content {background-color:$white;}
		h2.headline {color:$blue;}
		.contentbeneath {color: inherit;}


		@media (max-width:599px) {
			.filter-list-item.post.news.swiper-slide {
				width:260px;


			}
		}

	}

	.cu-card__post.cu-card__program.filter-list-item.post.news.swiper-slide .info {
		transition:none;
	}

	.cu-card__post.cu-card__program.filter-list-item.post.news.swiper-slide:hover .info  {
			position: absolute !important;
			height: 100% !important;
			.inner-content-wrap {opacity:1; display:block;}
		}

		.cu-card__post.cu-card__program.filter-list-item.post.news.swiper-slide:active .info  {
			position: absolute !important;
			height: 100% !important;
			.inner-content-wrap {opacity:1; display:block;}
		}	
		
		.cu-card__post.cu-card__program.filter-list-item.post.news.swiper-slide {width:320px; min-height:450px;}
		.cu-card__post.cu-card__program {height:auto;}
		.cu-card__post.cu-card__program .info .inner-content-wrap {-webkit-transition: opacity 0s; transition: opacity 0s;}

}