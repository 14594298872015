.page-template-page-general-template-7 {
	.background-wrap {
		position: relative;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 1100px;
			position: absolute;
			bottom: 190px;
			left: 0;
			background: $lgray;
		}

		> section {
			position: relative;
			z-index: 2;
		}

		@include max-down(1160px) {
			&:before {
				bottom: 65px;
			}
		}
	}

	#mentor-program {
		position: relative;

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 657px;
			position: absolute;
			bottom: -75px;
			left: 15px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;

			@include max-down(1160px) {
				width: 358px;
				height: 500px;
				left: -35px;
			}

			@include max-down(1000px) {
				display: none;
			}
		}
	}

	.page-background-wrap {
		padding-bottom: 360px;

		@include max-down(650px) {
			padding-bottom: 30px;
		}
	}

	.cu-section__colors {
		margin-top: -225px;

		@include max-down(650px) {
			margin-top: 0;
		}
	}
}