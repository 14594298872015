.cu-section__two-boxes {
	.inner-wrap {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
		width: 1170px;
		margin: 0 auto;

		.box {
			padding: 40px 0;
			background: $blue;

			&.bg-dblue {
				background: $dblue;
			}

			.inner-content-wrap {
				width: 460px;
				height: 100%;
				position: relative;
				margin: 0 auto;
				padding-bottom: 85px;

				.button-wrap {
					position: absolute;
					bottom: 0;
					left: 0;
				}
			}
		}
	}

	@include max-down(1300px) {
		.inner-wrap {
			width: 90%;

			.box .inner-content-wrap {
				width: 80%;
			}
		}
	}

	@include max-down(750px) {
		.inner-wrap {
			display: block;

			.box {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}

				.inner-content-wrap {
					height: auto;
					padding-bottom: 0;

					.button-wrap {
						position: static;
						margin-top: 30px;
					}
				}
			}
		}
	}

	@include max-down(600px) {
		.inner-wrap {
			width: calc(100% - 40px);
		}
	}
}