.cu-section__stats {
	position: relative;
	z-index: 2;
	padding: 200px 0 250px 0;

	.home &,
	.page-template-page-hub-admissions &,
	.page-template-page-hub-academics-location & {
		.stats .stat h2,
		.stats .stat .copy {
			color: $white;
		}
	}

	.stats {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		column-gap: 170px;
		width: 1175px;
		margin: 0 auto;

		.stat {
			h2 {
				font-size: 196px;
				font-weight: 300;
				line-height: 168px;
				letter-spacing: 0;
				color: $dblue;
			}

			.copy {
				color: $dblue;

				&:before {
					content: '';
					display: block;
					width: 60px;
					height: 2px;
					background: $dblue;
					margin: 30px 0 20px;
				}
			}
		}
	}

	&.bg {
		.stats {
			@include cutout-triangle-left;
			width: 1470px;
			padding: 40px 150px 60px;
			background: $gold;
			box-shadow: 6px 6px 24px rgba($black, 0.16);
		}
	}

	&.no-p {
		padding: 0;
	}

	&.blue-inset {
		margin-top: -155px;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 155px);
			position: absolute;
			top: 155px;
			left: 0;
			z-index: 1;
			background: $blue;
		}

		.stats {
			position: relative;
			z-index: 2;
		}
	}

	@include max-down(1600px) {
		&.bg {
			.stats {
				width: 95%;
				padding: 60px 10%;
				column-gap: 140px;
			}
		}
	}

	@include max-down(1300px) {
		.stats {
			width: 90%;
			column-gap: 140px;

			.stat h2 {
				font-size: 162px;
				line-height: 148px;
			}
		}

		&.bg .stats {
			column-gap: 120px;
		}
	}

	@include max-down(1160px) {
		&.bg .stats {
			padding: 60px 5%;
			column-gap: 45px;
		}
	}

	@include max-down(1000px) {
		padding: 155px 0 165px 0;

		.stats {
			column-gap: 65px;

			.stat h2 {
				font-size: 122px;
				line-height: 114px;
			}
		}
	}

	@include max-down(750px) {
		.stats {
			display: block;

			.stat {
				margin-bottom: 25px;
				text-align: center;

				&:last-child {
					margin-bottom: 0;
				}

				.copy:before {
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	@include max-down(600px) {
		padding: 90px 0 100px 0;
	}
}