.viewport {
	padding-top: 0;
	transition: padding-top .3s;
}

.notice-shown .viewport {
	padding-top: 40px;
}

.notice-shown .header-wrap {
	top: 40px;
}

.tbx-module__notice {
	display: block;
	width: 100%;
	max-width: 1920px;
	height: 40px;
	position: absolute;
	top: 0;
	right: calc((100% - 1920px)/2);

	&.brand {
		background: $lblue;
	}

	&.warning {
		background: red;
	}

	a {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 1;
		text-decoration: none;
	}

	.inner-content-wrap {
		width: 80%;
		margin: -5px auto 0 auto;
		text-align: center;
		color: #fff;

		h2 {
			display: inline-block;
			vertical-align: middle;
			margin-right: 30px;
			font-size: 28px;
			font-weight: 700;
			line-height: 1em;
			letter-spacing: 0.18em;
			text-transform: uppercase;
			color: $dblue;
		}

		p {
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;

			font-family: $ec_font;
			font-size: 28px;
			font-weight: 600;
			letter-spacing: 0.18em;
			text-transform: uppercase;
		}

		.button-wrap {
			display: inline-block;
			vertical-align: middle;
			margin-left: 30px;
		}
	}

	.close-notice {
		display: block;
		width: 20px;
		height: 20px;
		position: absolute;
		top: 50%;
		right: 20px;
		z-index: 2;
		transform: translateY(-50%);
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 2px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform-origin: 50% 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			background: #fff;
			transition: background .3s;
		}

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		&:hover {
			&:before,
			&:after {
				background: darken(#fff, 30%);
			}
		}
	}

	@include max-down(1921px) {
		right: 0;
	}

	@include max-down(1300px) {
		.inner-content-wrap {
			h2 {
				font-size: 1rem;
				margin-right: 15px;
			}

			p {
				font-size: 1rem;
				letter-spacing: 0.08em;
			}
		}
	}

	@include max-down(750px) {
		.inner-content-wrap {
			width: calc(100% - 40px);
			padding-right: 150px;
			text-align: left;

			h2 {
				display: none;
			}

			p {
				display: block;
			}

			.button-wrap {
				display: block;
				position: absolute;
				top: 50%;
				right: 60px;
				transform: translateY(-60%);
				margin-left: 0;
			}
		}
	}
}