.page-template-page-components, .program-template-page-components {

	.postlist.postrotator {
		padding-left: 30px; padding-right:30px; overflow: hidden;
		.btn {color:$blue;}
		.btn:hover {color:$white;}
		.filter-list-item.swiper-slide {
			width:330px;
		}

		.postlistimage {
			width:330px; height:226;
		img {
			width:100%;
		}
		
		}

		@media (max-width:599px) {
			.filter-list-item.swiper-slide {
				width:265px;
				height:460px;

				.info {height:100% !important;}



			}

			.postlistimage {width:265px; height:181px;}

				.swiper-button-next {
					display:block;
				}
				.swiper-button-next {
					display:block;
				}

		}
		.swiper-pagination-bullet {
			color:$white;
		}
		
		.swiper-pagination-bullet-active {
			background-color:$gold;
		}

		.swiper-pagination {
			bottom:-15px;
			display:none;
		}

		@media (max-width:599px) {
			.swiper-pagination {
				display:block;
			}
	
	}
		.swiper-containerrotateposts {padding-left:10px; padding-right:10px;}

		//.swiper-wrapper {padding:10px;}

		.cu-section__simple-content {
			background-color:$blue;
		}
		.cu-section__simple-content {
			background-color:$blue;
		}
		h2.headline {
			color:$white;
		} 
		.inner-wrap {
			padding:0 0 10px 0;
		} 
		.linewrapper{
			border-bottom: solid 2px $gold; text-align: center; margin-left:auto; margin-right:auto; display: inline-block; padding-bottom: 0px; margin-bottom: 15px; text-align: center;
		}
		
		.contentbeneath {
			color:$white; margin-top:50px;
		}
		
		.postexcerpt {
			color: #222222;
		}
		.cu-card__post {
			.info {
				padding-top:10px;
				.date {
					&:before {
						display: none;
					}
				}
			}
		}

		.cu-card__post:hover {
			box-shadow: 0px 0px 10px rgba(255, 255, 255, .66);
			-moz-box-shadow: 0px 0px 10px rgba(255, 255, 255, .66);
			-webkit-box-shadow: 0px 0px 10px rgba(255, 255, 255, .66);
		}

		.date {
			color: rgba(0,0,0,0);
		}
		h3.headline {
			text-transform: none;
			margin-bottom: 10px;
		}
		background-color: $blue;
		margin-top: 60px;
		margin-bottom: 30px;
	}
	.postlist.datespan {
		border-bottom: solid 1px $gold;
		color: $blue;
	}
	.postlist.cu-section__news-feed {
		.news-feed {
			.swiper-containerrotateposts {
				overflow:hidden;
				//padding:30px 10px;
				padding-top:30px; padding-bottom:30px;

			}



			.swiper-button-next {
				background-color: rgba(0,0,0,0);
				right: -60px;
			}
			.swiper-button-prev {
				background-color: rgba(0,0,0,0);
				right: -60px;
				left: -60px;
			}

			@media (max-width:599px) {
				.swiper-button-prev{
					display:block;
				}
				.swiper-button-next {
					display:block;
				}

			}
			
		}






	}
	





}