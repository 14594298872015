.search {
	.search-results-wrap {
		padding-top: 60px;
		padding-bottom: 150px;

		.tab {
			display: none;
			width: 1000px;
			margin: 0 auto;

			&:first-child {
				display: block;
			}

			.search-result {
				margin-bottom: 30px;
				background: #f2f2f2;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					text-decoration: none;
				}

				&.has-image {
					a {
						position: relative;
						padding-left: 275px;
					}

					.image {
						width: 275px;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
					}
				}

				.inner-content-wrap {
					width: 80%;
					margin: 0 auto;
					padding: 55px 0;

					.category {
						display: block;
						margin-bottom: 10px;

						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						text-decoration: none;
						color: $gold;
					}

					.button-wrap {
						margin-top: 35px;
					}
				}
			}
		}

		.button-wrap {
			width: 1000px;
			margin: 60px auto 0;
		}

		@include max-down(1160px) {
			.tab,
			.button-wrap {
				width: 90%;
			}
		}

		@include max-down(1000px) {
			padding-top: 100px;
			padding-bottom: 100px;

			.tab .search-result.has-image {
				a {
					padding-left: 0;
				}

				.image {
					display: none;
				}
			}
		}

		@include max-down(600px) {
			padding-top: 75px;
			padding-bottom: 75px;

			.tab {
				width: calc(100% - 40px);
			}
		}
	}

	.no-results {
		width: 1160px;
		margin: 0 auto;
		padding: 100px 0;

		.other-options {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 50px;
			padding: 65px 0;

			li {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		@include max-down(1300px) {
			width: 90%;
		}

		@include max-down(1000px) {
			.other-options {
				grid-template-columns: 1fr;
				row-gap: 35px;
			}
		}
	}
}