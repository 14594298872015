.cu-section__filter-bar-overlay {
	display: none;
	width: 100%;
	height: calc(100vh - 80px);
	position: fixed;
	top: 80px;
	z-index: 10;
	background: $white;

	.overlay-header {
		@include clearfix;
		width: calc(100% - 40px);
		margin: 0 auto;
		padding: 40px 0 20px 0;
		border-bottom: 1px solid $gold;

		h2 {
			float: left;
		}

		.close {
			float: right;
			margin-top: 6px;
		}

		.close-overlay {
			font-family: $c_font;
			font-size: 0.875rem;
			font-weight: 600;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $blue;

			.icon {
				display: inline-block;
				vertical-align: middle;
				width: 8px;
				height: 8px;
				margin-top: -3px;
				margin-left: 10px;
				background: url('../svg/close-blue.svg') center no-repeat;
				background-size: contain;
			}
		}
	}

	.overlay-body {
		width: calc(100% - 40px);
		height: calc(100vh - 350px);
		margin: 0 auto;
		overflow: auto;

		.mobile-filter-list {
			border-bottom: 1px solid $gold;

			&:last-child {
				border-bottom: 0;
			}
		}

		.mobile-filter-list-toggle {
			display: block;
			width: 100%;

			font-family: $c_font;
			font-size: 1.222rem;
			font-weight: 600;
			letter-spacing: 0.08em;
			line-height: 60px;
			text-transform: uppercase;
			color: $blue;
			text-align: left;

			&:after {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 9px;
				height: 8px;
				margin-left: 10px;
				transform: translateY(-2px);
				background: url('../svg/filter-arrow-blue.svg') center no-repeat;
				background-size: contain;
			}
		}

		ul {
			display: none;
			padding-bottom: 25px;

			li {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		input[type="checkbox"] {
			display: none;
		}

		label {
			display: block;
			position: relative;
			padding-left: 25px;

			font-family: $c_font;
			font-size: 1rem;
			line-height: 1.2em;
			font-weight: 600;
			letter-spacing: 0.08em;
			text-transform: uppercase;
			color: $blue;
			text-align: left;

			&:before {
				content: '';
				display: block;
				width: 14px;
				height: 14px;
				position: absolute;
				top: 5px;
				left: 0;
				border: 1px solid $gray;
				background: $white;
			}
		}

		input[type="checkbox"]:checked + label:before {
			background: $gold;
			border-color: $gold;
		}
	}

	.overlay-footer {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 30px 40px 30px;

		span {
			display: block;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;

				.btn {
					border-color: $blue;
					color: $blue;

					@include non-touch {
						&:hover {
							border-color: $gold;
							background: $gold;
							color: $white;
						}
					}
				}
			}

			.btn {
				width: 100%;
			}
		}
	}
}