.program-template-post-template-program-ind-v1 {
	.page-background-wrap {
		padding-bottom: 100px;

		@include max-down(600px) {
			padding-bottom: 65px;
		}
	}

	.main-wrap {
		position: relative;
		padding-top: 85px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 50px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('../svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			@include clearfix;
			width: 1250px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			.info {
				@include section-line-before(60px, 20px);
				width: 700px;
				float: left;
				margin-top: 45px;
				padding-left: 80px;

				.button-wrap {
					margin-top: 35px;
					font-size: 0;

					span {
						display: inline-block;
						vertical-align: middle;
						margin-right: 20px;

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.categories {
				width: 425px;
				float: right;
				margin: 0 0 0 auto;
				background: $blue;

				.inner-content-wrap {
					width: calc(100% - 100px);
					margin: 0 auto;
					padding: 50px 0;
				}

				.taxonomy {
					margin-bottom: 30px;

					.button-wrap {
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}

				h3 {
					margin-bottom: 10px;

					font-family: $c_font;
					font-size: 1rem;
					font-weight: 600;
					letter-spacing: 0.08em;
					text-transform: uppercase;
					color: $gold;

					.tooltip {
						display: inline-block;
						text-transform: none;

						a {
							color: $gold;
							text-decoration: none;
						}
					}
				}

				ul {
					li {
						margin-bottom: 10px;

						font-family: $c_font;
						font-size: 1rem;
						font-weight: 600;
						letter-spacing: 0.08em;
						text-transform: uppercase;
						color: $white;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		@include max-down(1400px) {
			.plus-pattern {
				right: -250px;
			}

			.inner-wrap {
				width: 90%;

				.info {
					width: 60%;
				}

				.categories {
					width: 35%;
				}
			}
		}

		@include max-down(1000px) {
			.plus-pattern {
				display: none;
			}

			.inner-wrap {
				.info {
					@include section-line-before-break;
					width: 100%;
					float: none;
					margin-top: 0;
					padding-left: 0;
				}

				.categories {
					width: 100%;
					float: none;
					margin-top: 55px;
				}
			}
		}

		@include max-down(600px) {
			.inner-wrap {
				width: calc(100% - 40px);
			}
		}
	}

	.vocation-wrap {
		position: relative;
		padding: 165px 0;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			top: 100px;
			right: -105px;
			transform: rotate(-90deg);
			background: url('../svg/plus-pattern-gold.svg') center no-repeat;
			background-size: contain;
		}

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 827px;
			position: absolute;
			bottom: -590px;
			left: -145px;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		.inner-wrap {
			width: 1400px;
			position: relative;
			z-index: 2;
			margin: 0 auto;

			.info {
				@include section-line-before(60px, 20px);
				width: 655px;
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				padding-left: 80px;
			}

			.side {
				width: 575px;
				margin: 0 0 0 auto;

				.careers,
				.internships {
					background: $blue;

					.inner-content-wrap {
						width: calc(100% - 100px);
						margin: 0 auto;
						padding: 50px 0;

						.copy ul li {
							margin-bottom: 0.05em;
						}
					}

					.opportunities {
						margin-top: 30px;

						h4 {
							margin-bottom: 10px;

							font-family: $c_font;
							font-size: 1rem;
							font-weight: 600;
							letter-spacing: 0.08em;
							text-transform: uppercase;
							color: $gold;
						}
						
						ul {
							columns: 2;

							li {
								margin-bottom: 10px;

								font-family: $c_font;
								font-size: 1rem;
								font-weight: 600;
								letter-spacing: 0.08em;
								text-transform: uppercase;
								color: $white;

								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}

				.internships {
					margin-top: 30px;
					background: $gold;

					.opportunities h4 {
						color: $blue;
					}
				}
			}
		}

		@include max-down(1550px) {
			.inner-wrap {
				width: 90%;

				.info {
					width: 50%;
				}

				.side {
					width: 45%;
				}
			}
		}

		@include max-down(1200px) {
			.plus-pattern {
				right: -250px;
			}

			.inner-wrap .info {
				@include section-line-before-break;
				padding-left: 0;
			}
		}

		@include max-down(1000px) {
			padding: 100px 0;

			.plus-pattern,
			.diamond-pattern {
				display: none;
			}

			.inner-wrap {
				.info {
					width: 100%;
					position: static;
					transform: none;
				}

				.side {
					width: 100%;
					margin: 0;
					margin-top: 75px;
				}
			}
		}

		@include max-down(750px) {
			padding: 75px 0;

			.inner-wrap {
				width: calc(100% - 40px);

				.side {
					.careers,
					.internships {
						.opportunities ul {
							columns: 1;
						}
					}
				}
			}
		}
	}

	.cu-section__stats {
		margin-bottom: 100px;

		@include max-down(600px) {
			margin-bottom: 65px;
		}
	}

	.background-wrap {
		position: relative;

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 505px);
			position: absolute;
			bottom: 70px;
			left: 0;
			background: $lgray;
		}

		.expanders-wrap {
			padding: 40px 0 155px 0;

			@include max-down(1000px) {
				padding-bottom: 100px;
			}

			@include max-down(600px) {
				padding-bottom: 75px;
			}
		}
	}

	.expanders-wrap {
		position: relative;
		z-index: 2;
		padding: 100px 0 0 0;

		.cu-part__expanders {
			width: 1170px;
			margin: 0 auto;
		}

		@include max-down(1300px) {
			.cu-part__expanders {
				width: 90%;
			}
		}

		@include max-down(600px) {
			padding-top: 0;

			.cu-part__expanders {
				width: calc(100% - 40px);
			}
		}
	}

	.cu-section__half.two-images {
		position: relative;
		z-index: 2;
		padding-bottom: 150px;
	}

	.blue-background-wrap {
		position: relative;
		z-index: 1;

		&.no-outcomes {
			margin-top: 100px;

			@include max-down(600px) {
				margin-top: 65px;
			}
		}

		.diamond-pattern {
			display: block;
			width: 470px;
			height: 827px;
			position: absolute;
			top: -350px;
			left: 10px;
			z-index: 2;
			background: url('../svg/diamond-pattern-gold-5-column.svg') center no-repeat;
			background-size: contain;
		}

		&:after {
			content: '';
			display: block;
			width: 100%;
			height: calc(100% - 360px);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
			background: $blue;
		}

		&.no-testimonials:after {
			height: 100%;
		}

		.cu-section__testimonial {
			position: relative;
			z-index: 2;
		}

		.cu-section__faculty-list {
			position: relative;
			z-index: 2;
		}

		@include max-down(1000px) {
			.diamond-pattern {
				display: none;
			}
		}
	}

	.cu-section__programs-list {
		padding-bottom: 220px;

		@include max-down(1000px) {
			padding-bottom: 100px;
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}

	.contact-bar-wrap {
		position: relative;
		padding-bottom: 245px;

		.plus-pattern {
			display: block;
			width: 707px;
			height: 654px;
			position: absolute;
			bottom: 45px;
			left: -205px;
			background: url('../svg/plus-pattern-gray.svg') center no-repeat;
			background-size: contain;
		}

		@include max-down(1000px) {
			padding-bottom: 100px;

			.plus-pattern {
				display: none;
			}
		}

		@include max-down(600px) {
			padding-bottom: 75px;
		}
	}
}