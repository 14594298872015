.cu-section__contact-bar {
	width: 1470px;
	position: relative;
	margin: 0 auto;
	box-shadow: 6px 6px 24px rgba($black, 0.16);
	background: $white;

	&.has-image {
		.left {
			@include clearfix;

			.image {
				width: 200px;
				height: 200px;
				float: left;
				margin-right: 50px;
				margin-left: 50px;
				border-radius: 50%;
				overflow: hidden;
			}

			.inner-content-wrap {
				width: calc(100% - 300px);
				float: left;
				padding-right: 15px;
			}
		} 

		@include max-down(1260px) {
			padding-left: 0;

			.left {
				.image {
					float: none;
					margin-bottom: 35px;
				}

				.inner-content-wrap {
					width: calc(100% - 50px);
					float: none;
					margin-left: 50px;
					padding-right: 0;
				}
			}
		}

		@include max-down(1000px) {
			.left {
				.image {
					margin: 0 auto 35px auto;
				}

				.inner-content-wrap {
					margin: 0 auto;
				}
			}
		}
	}

	.left {
		width: calc(100% - 200px);
		padding: 35px 0;

		.inner-content-wrap {
			@include section-line-before(60px, 20px);
			width: calc(100% - 140px);
			margin: 0 auto;
			padding-left: 80px;

			.copy {
				width: 670px;
				margin-top: 25px;
			}

			.contact-info {
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				column-gap: 50px;
				margin-top: 25px;
				font-size: 0;

				h4 {
					font-family: $sc_font;
					font-size: 1rem;
					font-weight: 700;
					text-transform: uppercase;
					color: $gray;
				}

				a {
					color: inherit;
					text-decoration: none;
				}

				> div {
					font-size: 1rem;
					line-height: 1.55em;
				}

				.contact .info,
				.location .info {
					dt {
						display: inline;

						font-family: $sc_font;
						font-size: 1rem;
						font-weight: 700;
						text-transform: uppercase;
						color: $gray;
					}

					dd {
						display: inline;
						font-family: $sc_font;
					}
				}

				.address span,
				.hours span {
					display: block;
					max-width: 220px;
					font-family: $sc_font;
				}
			}
		}
	}

	.right {
		display: grid;
		grid-template-rows: 1fr 1fr;
		width: 200px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;

		.button {
			.btn {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
			}
		}
	}

	@include max-down(1600px) {
		width: 90%;
	}

	@include max-down(1400px) {
		.left .inner-content-wrap {
			@include section-line-before(30px, 20px);
			width: calc(100% - 70px);
			margin: 0 0 0 auto;
			padding-left: 50px;

			.copy {
				width: 100%;
				padding-right: 25px;
			}
		}
	}

	@include max-down(1160px) {
		.left {
			width: 100%;

			.inner-content-wrap {
				width: calc(100% - 35px);
			}
		}

		.right {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr;
			width: 100%;
			height: 100px;
			position: static;
		}
	}

	@include max-down(1000px) {
		.left {
			.headline {
				text-align: center;
			}

			.inner-content-wrap {
				@include section-line-before-break;
				width: 100%;
				padding-left: 0;
				margin: 0 auto;

				&:before {
					margin-left: auto;
					margin-right: auto;
				}

				.contact-info {
					display: grid;
					grid-template-columns: 1fr 1fr;
					column-gap: 50px;
					width: 80%;
					margin: 25px auto 0;

					> div {
						margin-bottom: 35px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}

		&.layout-description {
			.left {
				width: 90%;
				margin: 0 auto;

				.inner-content-wrap .copy {
					padding-right: 0;
				}
			}
		}
	}

	@include max-down(750px) {
		margin: 0 auto;

		.left .inner-content-wrap {
			.contact-info {
				grid-template-columns: 1fr;
			}
		}
	}
}