.page-template-page-components, .program-template-page-components {

    .cu-section__half {

        padding-bottom:0px;
    }

.cu-section__half.two-images .images {
    position: relative
}

.cu-section__half.two-images .images .image-2 {
    width: 275px;
    height: 280px;
    position: absolute;
    z-index: 2
}

.cu-section__half.two-images .images .image-2 .image {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}

.cu-section__half.two-images.image-top-left .images .image-2 {
    top: -70px;
    left: -70px
}

.cu-section__half.two-images.image-bottom-left .images .image-2 {
    bottom: -70px;
    left: -70px
}

.cu-section__half.two-images.image-bottom-right .images .image-1 {
    margin: 0
}

.cu-section__half.two-images.image-bottom-right .images .image-2 {
    bottom: -70px;
    right: 0
}

.cu-section__half.two-images.image-right-middle-top {
    padding-bottom: 100px
}

.cu-section__half.two-images.image-right-middle-top .images .image-1 {
    margin: 0
}

.cu-section__half.two-images.image-right-middle-top .images .image-2 {
    top: 45px;
    right: 0
}

.cu-section__half.two-images.image-right-middle-bottom {
    padding-bottom: 100px
}

.cu-section__half.two-images.image-right-middle-bottom .images .image-1 {
    margin: 0
}

.cu-section__half.two-images.image-right-middle-bottom .images .image-2 {
    bottom: 45px;
    right: 0
}

.cu-section__half.two-images.no-bottom-padding.image-bottom-left,.cu-section__half.two-images.no-bottom-padding.image-bottom-right {
    padding-bottom: 70px
}

.cu-section__half.two-images.no-bottom-padding.image-right-middle-bottom,.cu-section__half.two-images.no-bottom-padding.image-right-middle-top {
    padding-bottom: 0
}

.cu-section__half.cu24 .inner-wrap .info:before {display:none;}

.cu-section__half.cu24 .inner-wrap {padding-bottom:65px;}

.cu-section__half.cu24.blue {background: linear-gradient(to bottom, $white, $white 20px, $blue 20px, $blue 95%, white 95%, white);}

.cu-section__half.cu24.blue h1 {color:$white;}

.cu-section__half.cu24 .linewrapper {border-bottom: 2px solid $gold;
    display: inline-block;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
    text-align: center;}

.cu-section__half.cu24.blue .cu-section__simple-content {background-color:rgba(0,0,0,0);}

.cu-section__half.cu24 .inner-wrap .info  {
    position:relative;
    margin-bottom:55px;
}

.cu-section__half.cu24.blue .inner-wrap h2, .cu-section__half.cu24.blue .inner-wrap .copy {color:$white;}

@media only screen and (min-width: 1160px){



.cu-section__half.cu24 .inner-wrap .copy {padding-top:15px;}

.cu-section__half.cu24 .hdrline {width:100%; border-top: solid 2px $gold;}

.cu-section__half.cu24 .inner-wrap .info {position: relative; -webkit-transform:none; transform: none; height:auto; margin-top: -450px; min-height:450px; padding-bottom:20px;}

.cu-section__half.cu24 .inner-wrap .info .button-wrap {margin-bottom: 40px;}

.cu-section__half.cu24 .inner-wrap .info {margin: 0 0 0 auto; margin-top:-450px;}

.cu-section__half.cu24.white .inner-wrap .info {margin-top:-540px;}

.cu-section__half.cu24.white.notflipped .inner-wrap .info {float:right;}

.cu-section__half.cu24.flipped .inner-wrap .info {margin: -500px 0 0 0;}

.cu-section__half.cu24.white.flipped .inner-wrap .info {margin: -540px 0 0 0;}
}
@media only screen and (min-width:999px){
    .cu-section__half.cu24 .image-1 {box-shadow: -15px 15px 0px 0px $gold;}


}
@media only screen and (min-width: 1000px) and (max-width:1159px){
    .cu-section__half.cu24.white .inner-wrap .info {transform:none; position:relative; margin-top:-375px;}
    .cu-section__half.cu24.white.notflipped .inner-wrap .info {float:right;}
}


@media only screen and (min-width: 1549px) {
    .cu-section__half.cu24.blue h2 {margin-top:20px;}

}

@media only screen and (max-width: 1549px) {
    .cu-section__half {padding: 50px 0px 0px;}
   
    .cu-section__half .inner-wrap {
        width:90%
    }

    .cu-section__half .inner-wrap .info {
        width: calc(100% - 700px)
    }

    .cu-section__half.two-images.image-bottom-left .images .image-2,.cu-section__half.two-images.image-top-left .images .image-2 {
        left: 0
    }
}

@media only screen and (max-width: 1259px) {
    .cu-section__half .inner-wrap .info {
        padding-left:0
    }

    .cu-section__half .inner-wrap .info:before {
        position: static;
        margin-bottom: 15px
    }
}

@media only screen and (min-width: 1160px) {.cu-section__half .inner-wrap .info .copy {padding-bottom:60px;}
}

@media only screen and (max-width: 1159px) {
    .cu-section__half .inner-wrap .images {
        width:425px;
        height: 375px
    }

    .cu-section__half .inner-wrap .images .image-1 {
        width: 400px
    }

    .cu-section__half .inner-wrap .info {
        width: calc(100% - 475px)
    }

    .cu-section__half.third-image .image-3 {
        width: 425px;
        height: 388px
    }

    .cu-section__half.two-images.image-bottom-left .images .image-2,.cu-section__half.two-images.image-bottom-right .images .image-2,.cu-section__half.two-images.image-right-middle-bottom .images .image-2,.cu-section__half.two-images.image-right-middle-top .images .image-2,.cu-section__half.two-images.image-top-left .images .image-2 {
        width: 200px;
        height: 210px
    }
}

@media only screen and (max-width: 999px) {
    .cu-section__half .diamond-pattern {
        display:none
    }

    .cu-section__half .inner-wrap {
        width: 100%
    }

    .cu-section__half .inner-wrap .images {
        width: 80%;
        height: 440px;
        margin: 0 auto
    }

    .cu-section__half .inner-wrap .images .image-1 {
        width: 100%
    }

    .cu-section__half .inner-wrap .info {
        width: 80%;
        position: static;
        -webkit-transform: none;
        transform: none;
        margin: 55px auto 0
    }

    .cu-section__half.third-image {
        padding-top: 100px
    }

    .cu-section__half.third-image .image-3 {
        display: none
    }

    .cu-section__half.third-image.no-top-padding {
        padding-top: 0
    }

    .cu-section__half.two-images {
        padding-bottom: 100px!important
    }

    .cu-section__half.two-images.image-top-left .images {
        width: 80%;
        height: 465px;
        padding-top: 30px
    }

    .cu-section__half.two-images.image-top-left .images .image-1 {
        width: 80%;
        height: 440px
    }

    .cu-section__half.two-images.image-top-left .images .image-2 {
        top: 0
    }

    .cu-section__half.two-images.image-bottom-left .images,.cu-section__half.two-images.image-bottom-right .images {
        width: 80%;
        height: 465px
    }

    .cu-section__half.two-images.image-bottom-left .images .image-1,.cu-section__half.two-images.image-bottom-right .images .image-1 {
        width: 80%;
        height: 440px
    }

    .cu-section__half.two-images.image-bottom-left .images .image-2,.cu-section__half.two-images.image-bottom-right .images .image-2 {
        bottom: 0
    }

    .cu-section__half.two-images.image-right-middle-bottom .images,.cu-section__half.two-images.image-right-middle-bottom .images .image-1,.cu-section__half.two-images.image-right-middle-top .images,.cu-section__half.two-images.image-right-middle-top .images .image-1 {
        width: 80%;
        height: 440px
    }

    .cu-section__half.two-images.no-bottom-padding {
        padding-bottom: 0!important
    }

    .cu-section__half.long-text .inner-wrap .images {
        float: none
    }

    .cu-section__half.long-text .inner-wrap .info {
        float: none;
        margin-top: 55px
    }
}

@media only screen and (max-width: 599px) {
    .cu-section__half .inner-wrap {
        width:calc(100% - 40px)
    }

    .cu-section__half .inner-wrap .images,.cu-section__half .inner-wrap .images .image-1 {
        width: 100%;
        height: auto
    }

    .cu-section__half .inner-wrap .info {
        width: 100%
    }

    .cu-section__half.two-images.image-bottom-left .images,.cu-section__half.two-images.image-bottom-left .images .image-1,.cu-section__half.two-images.image-bottom-right .images,.cu-section__half.two-images.image-bottom-right .images .image-1,.cu-section__half.two-images.image-right-middle-bottom .images,.cu-section__half.two-images.image-right-middle-bottom .images .image-1,.cu-section__half.two-images.image-right-middle-top .images,.cu-section__half.two-images.image-right-middle-top .images .image-1,.cu-section__half.two-images.image-top-left .images,.cu-section__half.two-images.image-top-left .images .image-1 {
        width: 100%;
        height: auto
    }

    .cu-section__half.two-images.image-bottom-left .images .image-2,.cu-section__half.two-images.image-bottom-right .images .image-2,.cu-section__half.two-images.image-right-middle-bottom .images .image-2,.cu-section__half.two-images.image-right-middle-top .images .image-2,.cu-section__half.two-images.image-top-left .images .image-2 {
        display: none
    }
}

@media only screen and (max-width: 374px) {
    .cu-section__half .inner-wrap .info .button-wrap span {
        display:block;
        margin-right: 0;
        margin-bottom: 15px
    }

    .cu-section__half .inner-wrap .info .button-wrap span:last-child {
        margin-bottom: 0
    }
}
}