.cu-section__cta-bar {
	@include clearfix;
	width: 1470px;
	position: relative;
	z-index: 2;
	margin: 0 auto;
	background: $dblue;

	&.tbx-animated {
		.right .button {
			opacity: 0;
		}

		&.tbx-animated-siv-ft {
			.right .button {
				&:nth-child(1) {
					@include fadeInRight($duration: 0.7s);
				}

				&:nth-child(2) {
					@include fadeInRight($duration: 0.7s, $delay: 0.35s);
				}
			}
		}

		@include max-down(1160px) {
			&.tbx-animated-siv-ft {
				.right .button {
					&:nth-child(1) {
						@include fadeInUp($duration: 0.7s);
					}

					&:nth-child(2) {
						@include fadeInUp($duration: 0.7s, $delay: 0.35s);
					}
				}
			}
		}

		@include max-down(600px) {
			.right .button {
				opacity: 1;
				animation: none !important;
			}
		}
	}

	.left {
		width: calc(100% - 400px);
		float: left;
		padding: 0 40px;
		font-size: 0;

		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 0;
			height: 140px;
		}

		h2 {
			display: inline-block;
			vertical-align: middle;
			margin-top: -5px;
		}
	}

	.right {
		width: 400px;
		float: right;
		font-size: 0;

		.button {
			display: inline-block;
			vertical-align: middle;
			width: 200px;
			height: 140px;

			.btn {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
			}
		}
	}

	@include max-down(1600px) {
		width: 95%;
	}

	@include max-down(1160px) {
		.left {
			width: 100%;
			float: none;
			padding: 30px 5% 35px 5%;
			text-align: center;

			&:before {
				display: none;
			}

			h2 {
				display: block;
				margin-top: 0;
			}
		}

		.right {
			width: 100%;
			float: none;

			.button {
				width: 50%;
				height: 100px;
			}
		}
	}

	@include max-down(600px) {
		width: calc(100% - 40px);
	}
}