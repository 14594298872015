.page-template-page-general-apply {
	.page-background-wrap {
		padding-bottom: 275px;

		@include max-down(750px) {
			padding-bottom: 10vw;
		}
	}

	.application-portal {
		width: 1215px;
		position: relative;
		margin: 0 auto 60px auto;
		box-shadow: 6px 6px 24px rgba($black, 0.16);
		background: $white;

		&.tbx-animated {
			opacity: 0;

			&.tbx-animated-siv-ft {
				@include fadeInUp($duration: 0.7s);
			}
		}

		.left {
			width: 100%;

			.inner-content-wrap {
				width: calc(100% - 240px);
				margin: 0 auto;
				padding: 60px 0;

				.inner {
					@include section-line-before(30px, 15px);
					padding-left: 50px;
				}

				.copy {
					width: 100%;
				}
			}
		}

		.right {
			display: grid;
			grid-template-rows: 1fr 1fr;
			width: 200px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;

			.button-wrap {
				.btn {
					display: flex;
					width: 100%;
					height: 100%;
					justify-content: center;
					align-items: center;
				}
			}

			.video-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		@include max-down(1350px) {
			width: 90%;
		}

		@include max-down(1160px) {
			.left .inner-content-wrap {
				width: calc(100% - 120px);
			}
		}

		@include max-down(850px) {
			.left .inner-content-wrap {
				width: calc(100% - 80px);

				.inner {
					@include section-line-before-break;
					padding-left: 0;
				}
			}
		}

		@include max-down(750px) {
			.left {
				width: 100%;
			}

			.right {
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr;
				width: 100%;
				height: 100px;
				position: static;
			}
		}

		@include max-down(600px) {
			.left .inner-content-wrap {
				width: calc(100% - 70px);
				padding: 35px 0;
			}
		}
	}

	.cu-section__contact-bar {
		margin-top: 200px;

		@include max-down(750px) {
			margin-top: 0;
		}
	}
}