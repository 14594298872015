.landing-page-footer-wrap {
	background: $blue;

	.footer {
		width: 875px;
		margin: 0 auto;
		padding: 45px 0;
		text-align: center;
	}

	.logo {
		@include hidden-text;
		display: block;
		width: 200px;
		height: 108px;
		margin: 0 auto 35px;
		background: url('../svg/logo-stacked-white.png') center no-repeat;
		background-size: contain;
	}

	.footer-nav {
		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 25px;

			&:last-child {
				margin-right: 0;
			}

			a {
				font-size: 0.875rem;
				font-weight: 700;
				letter-spacing: 0.02em;
				color: $gray;
				text-decoration: none;

				@include non-touch {
					transition: color .3s;

					&:hover {
						color: $gold;
					}
				}
			}
		}
	}

	.notice {
		margin-top: 25px;

		span {
			font-size: 0.777rem;
			font-weight: 700;
			color: $gray;
			text-decoration: none;
		}
	}

	.copyright {
		margin-top: 30px;

		span {
			font-size: 0.777rem;
			font-weight: 700;
			color: $gray;
			text-decoration: none;
		}
	}

	.crafted-by-tbx {
		margin-top: 30px;
	}

	&.three-column {
		.footer {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			width: 100%;
			padding: 45px 80px;
			text-align: left;
		}

		.middle {
			width: 675px;
		}

		.right {
			width: 430px;
		}

		.logo {
			margin: 0;
		}

		.footer-nav li {
			margin-bottom: 15px;
		}

		.copyright {
			margin-top: 15px;
		}

		.notice {
			margin-top: 0;
		}
	}

	@include max-down(1550px) {
		&.three-column {
			.middle {
				width: 35%;
			}

			.right {
				width: 30%;
			}
		}
	}

	@include max-down(1000px) {
		.footer {
			width: 90%;
		}

		.footer-nav li {
			display: block;
			margin-bottom: 15px;
			margin-right: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&.three-column {
			.footer {
				display: block;
				width: 90%;
				padding: 45px 0;
				text-align: center;
			}

			.middle,
			.right {
				width: 100%;
			}

			.logo {
				margin: 0 auto 35px;
			}

			.notice {
				margin-top: 30px;
			}
		}
	}
}